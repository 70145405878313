<mat-card  class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="panel-body m-2 p-2 flex flex-col gap-4"  #FILTRO >
      <div class="flex flex-col gap-4 px-2">
        <p class="text-blue-950 text-2xl font-bold">Directores</p>
        <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
          <div class="flex flex-row gap-1 py-2">
            <mat-icon>filter_list</mat-icon>
            <p class="text-lg font-bold">Filtros</p>
          </div>

        <div class="row ms-auto my-auto d-flex">
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xl-4">
            <mat-form-field class="fullWidth">
              <mat-label>RUT Director</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345678" [(ngModel)]="filtros.rutDirector" name="rutDirector" />
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xl-4">
            <mat-form-field class="fullWidth">
              <mat-label>RBD</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345" class="fullWidth" [(ngModel)]="filtros.rbd" name="rbd" />

            </mat-form-field>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xl-4">
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>Dependencia</mat-label>
              <mat-select [(ngModel)]="filtros.dependencia" name="dependencia">
                <mat-option [value]="null">todos</mat-option>
                <mat-option *ngFor="let dependencia of dependencias" [value]="dependencia.id">
                  {{dependencia.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xl-4">
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>Región</mat-label>
              <mat-select [(ngModel)]="filtros.region" name="region">
                <mat-option [value]="null">todas</mat-option>
                <mat-option *ngFor="let region of regiones" [value]="region.idRegion">
                  {{region.simbolo + ' - ' + region.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xl-4">
            <mat-form-field class="fullWidth">
              <mat-label>RUT Sostenedor</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345678" [(ngModel)]="filtros.rutSostenedor" name="rutSostenedor" />
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xl-4">
            <mat-form-field class="fullWidth">
              <mat-label>RUT Encargado</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345678" [(ngModel)]="filtros.rutEncargado" name="rutEncargado" />
            </mat-form-field>
          </div>
          <mat-card-actions class="d-flex row">
            <div class="float-right col d-flex justify-content-start">
              <button
                type="button"
                data-te-ripple-init
                (click)="filtrar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                <p class="pr-1"></p> Filtrar
              </button>

              <button
                  matTooltip="Descarga esta tabla completa"
                  *ngIf="length>0"
                  type="button"
                  data-te-ripple-init
                  (click)="descargarCsv()"
                  data-te-ripple-color="light"
                  class="d-flex w-fit px-2 mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                  <fa-icon *ngIf="descargandoCsv" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
                  <svg *ngIf="!descargandoCsv" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pr-2" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
                  <div *ngIf="!descargandoCsv" class="text-[14px]">Descargar</div>
                  <div *ngIf="descargandoCsv" class="text-[14px]">Descargando</div>
              </button>

              <button
                type="button"
                data-te-ripple-init
                (click)="limpiar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg xmlns="http://www.w3.org/2000/svg" class="pr-1" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                Limpiar
              </button>
            </div>
            <div class="float-right col d-flex justify-content-end">
              <button
                type="button"
                matTooltip="Crear un nuevo Director"
                data-bs-toggle="modal" data-bs-target="#modalDirector"
                data-te-ripple-init
                data-te-ripple-color="light"
                (click)="agregando()"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-plus" viewBox="0 0 16 16"> <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/> <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/> </svg>
                <p class="pr-1"></p> Agregar
              </button>
            </div>
          </mat-card-actions>
        </div>
      </div>
    </div>
  </div>

  <div class="overflow-x-scroll rounded-2xl border border-gray-200 m-2 p-2"  aria-label="sección tabla">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table class="w-full table-auto table-hover rounded-2xl table bg-gray-50" aria-label="tabla encargados" role="table">
      <thead class="bg-blue-700 ">
        <tr >
          <th scope="col" class="rounded-tl-2xl pl-2 pr-4 py-3 text-left text-blue-100 text-sm font-black w-14 items-baseline" ></th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " >RUT</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " >Empresa Sostenedora</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " >Establecimiento Asociado</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " >Nombre</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " >Contacto Director/a</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="containRol()" >Acción</th>
          <th scope="col" class="rounded-tr-2xl pl-2 pr-4 py-3 text-right text-blue-100 w-14" ></th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <tr class="min-w-full" *ngFor="let element of response">
          <td class="negrita-tabla py-2 mr-4">
          </td>
          <td class="negrita-tabla py-2 mr-4">
            <button type="button"
              title="Ver los establecimientos asociados"
              class="inline-flex gap-1 rounded-xl border-2 border-primary px-6 pb-[6px] pt-2 text-[14px]
              font-bold uppercase leading-normal text-primary transition duration-150 ease-in-out
              hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600
              focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0
              active:border-primary-700 active:text-primary-700 "
              data-bs-toggle="modal" data-bs-target="#modalDetalles"
              (click) = "setEncargadoSeleccionado(element)"
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><rect fill="none"/><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/></svg>
              <p class="text-sm">{{element.rut}}</p>
            </button>
          </td>
          <td class="negrita-tabla">
            <p class="text-sm font-bold text-slate-600 flex flex-row gap-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M10 16v-1H3.01L3 19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V5l-2-2h-4l-2 2v2H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 0h-4V5h4v2z"/></svg>
              {{element.rutSostenedor}}</p>
              <div class="w-full font-bold text-md text-gray-500">{{element.nombreEstablecimiento}}</div>
          </td>
          <td class="negrita-tabla">
            <div class="flex flex-col py-2">
              <div class="w-full font-bold text-md text-gray-500">{{element.establecimiento}}</div>
              <div class="flex flex-row gap-1">
                <span class="rounded bg-blue-600 font-bold text-blue-100 text-[0.6em] px-1">RBD</span>
                <div class="w-full font-bold text-md text-gray-500">{{element.rbd}}</div>
              </div>
              <div class="flex flex-row gap-1">
                <span class="rounded bg-green-600 font-bold text-blue-100 text-[0.6em] px-1">{{element.dependencia}}</span>
              </div>
              <div class="flex flex-row gap-1">
                <span class="rounded bg-yellow-600 font-bold text-blue-100 text-[0.6em] px-1">{{element.region}}</span>
              </div>
            </div>
          </td>
          <td class="negrita-tabla">{{element.nombres + " " + element.primerApellido + " " + element.segundoApellido }}</td>
          <td class="negrita-tabla">
            <div class="flex flex-col py-2 pl-2 mr-4 gap-2">
              <div class="w-full text-xs text-ellipsis text-gray-500 flex flex-row gap-1" title="{{element.correo}}">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"/></svg>
                {{element.correo!=null ? element.correo : 'Correo General'}}</div>
                <div class="text-xs text-gray-500 flex flex-row gap-1" title="Número de contacto del encargado">
                  <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone-vibrate" viewBox="0 0 16 16"> <path d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4zM6 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6z"/> <path d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM1.599 4.058a.5.5 0 0 1 .208.676A6.967 6.967 0 0 0 1 8c0 1.18.292 2.292.807 3.266a.5.5 0 0 1-.884.468A7.968 7.968 0 0 1 0 8c0-1.347.334-2.619.923-3.734a.5.5 0 0 1 .676-.208zm12.802 0a.5.5 0 0 1 .676.208A7.967 7.967 0 0 1 16 8a7.967 7.967 0 0 1-.923 3.734.5.5 0 0 1-.884-.468A6.967 6.967 0 0 0 15 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 0 1 .208-.676zM3.057 5.534a.5.5 0 0 1 .284.648A4.986 4.986 0 0 0 3 8c0 .642.12 1.255.34 1.818a.5.5 0 1 1-.93.364A5.986 5.986 0 0 1 2 8c0-.769.145-1.505.41-2.182a.5.5 0 0 1 .647-.284zm9.886 0a.5.5 0 0 1 .648.284C13.855 6.495 14 7.231 14 8c0 .769-.145 1.505-.41 2.182a.5.5 0 0 1-.93-.364C12.88 9.255 13 8.642 13 8c0-.642-.12-1.255-.34-1.818a.5.5 0 0 1 .283-.648z"/> </svg>
                  {{element.telefono !=null ? element.telefono : 'Teléf. General'}}</div>

            </div>
          </td>
          <td class=" overflow-hidden shadow-4" *ngIf="containRol()">
            <div class="inline-flex rounded-full">
              <button data-bs-toggle="modal" data-bs-target="#modalDirector" hidden #abremodal></button>
              <button class="d-flex align-items-center justify-content-center rounded-l-full border-[.1rem] border-[#005A9C] ps-2 pe-1 py-1 text-[14px] font-medium leading-normal transition duration-150 ease-in-out hover:text-primary-600"
              data-te-ripple-init
              data-te-ripple-color="light"
              (click)="editando(element.rut, element.rbd, element.nombres, element.primerApellido, element.segundoApellido, element.correo, element.telefono)">
              <mat-icon>edit</mat-icon>
              </button>
              <button
                (click) = "eliminarDirector(element.rut, element.rbd)"
                class="d-flex align-items-center justify-content-center rounded-r-full  border-[.1rem] border-[#005A9C] pe-2 ps-1 py-1 text-[10px] font-medium leading-normal transition duration-150 ease-in-out hover:text-primary-600"
                        data-te-ripple-init
                        data-te-ripple-color="light">
                        <mat-icon>delete</mat-icon>
              </button>

            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
    [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="Select page of periodic elements">
  </mat-paginator>
  </div>
</mat-card>


<!-- Modal Director Agregar/Editar -->
<div class="modal fade" id="modalDirector" tabindex="-1" aria-labelledby="modalDirector" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content rounded-lg shadow-lg">
      <div class="modal-header flex justify-between items-center">
        <h5 *ngIf="this.estaAgregando" class="modal-title font-medium" id="fichaSeguimientoLabel">Agregar Director</h5>
        <h5 *ngIf="this.estaEditando"  class="modal-title font-medium" id="fichaSeguimientoLabel">Editar Director</h5>
        <div>
          <button #cerrarFormModal type="button" class="border hover:border-black py-1 px-2 rounded-xl"
          data-bs-dismiss="modal" aria-label="Close">&times;</button>
        </div>
      </div>
      <div class="modal-body p-6">
        <form [hidden]="isLoading" [formGroup]="directorForm" class="py-2 px-8">
          <!-- RUT -->
          <div class="mb-4 fade-in" *ngIf="this.estaAgregando">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Rut</mat-label>
              <input #rut matInput  formControlName="rut" name="rut"
                      placeholder="Ingrese Rut" type="number">
            </mat-form-field>
          </div>
          <div class="mb-4 fade-in" *ngIf="this.estaEditando">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Rut</mat-label>
              <input #rut matInput  formControlName="rut" name="rutEdicion"
                      placeholder="Ingrese Rut" type="number" [readonly]="true" class="custom-disabled">
            </mat-form-field>
          </div>

          <!-- RBD -->
          <div class="mb-4 fade-in" *ngIf="this.estaAgregando">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> RBD</mat-label>
              <input #rbd matInput  formControlName="rbd" name="rbd"
                      placeholder="Ingrese RBD" type="number">
            </mat-form-field>
            <p>{{this.nombreEstablecimiento}}</p>
          </div>

          <div class="mb-4 fade-in" *ngIf="this.estaEditando">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> RBD</mat-label>
              <input #rbd matInput  formControlName="rbd" name="rbd"
                      placeholder="Ingrese RBD" type="number" [readonly]="true"
                      class="custom-disabled">
            </mat-form-field>
            <p>{{this.nombreEstablecimiento}}</p>
          </div>

          <!-- Nombres -->
          <div class="mb-4 fade-in">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Nombres</mat-label>
              <input #nombres matInput  formControlName="nombres" name="nombres"
                      placeholder="Ingrese Nombres" type="string">
            </mat-form-field>
          </div>

          <!-- Primer Apellido -->
          <div class="mb-4 fade-in">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Primer Apellido</mat-label>
              <input #primerApellido matInput  formControlName="primerApellido" name="primerApellido"
                      placeholder="Ingrese Primer Apellido" type="string">
            </mat-form-field>
          </div>

          <!-- Segundo Apellido -->
          <div class="mb-4 fade-in">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Segundo Apellido</mat-label>
              <input #segundoApellido matInput  formControlName="segundoApellido" name="segundoApellido"
                      placeholder="Ingrese Segundo Apellido" type="string">
            </mat-form-field>
          </div>

          <!-- Correo -->
          <div class="mb-4 fade-in">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Correo</mat-label>
              <input #correo matInput  formControlName="correo" name="correo"
                      placeholder="Ingrese Correo" type="string">
            </mat-form-field>
          </div>

          <!-- Telefono -->
          <div class="mb-4 fade-in">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Teléfono</mat-label>
              <input #telefono matInput  formControlName="telefono" name="telefono"
                      placeholder="Ingrese teléfono" type="number">
            </mat-form-field>
          </div>
        </form>

      </div>
      <!-- Botones -->
      <div>
        <div class="blue-text-box" *ngIf="this.nombreEstablecimiento == ''">
          <p>No se encuentra un director asociado a este RBD</p>
        </div>
        <div class="red-text-box" *ngIf="this.nombreEstablecimiento != ''">
          <p>El RBD tiene un director asociado</p>
        </div>
        <div id="modalBotones" aria-hidden="true" class="flex justify-end gap-2 fade-in modal-footer sticky bottom-0 bg-white filtros2 z-10" >
          <button mat-button type="button" class="text-gray-500" data-bs-dismiss="modal">Cancelar</button>
          <div #botonesSubmitForm *ngIf="!isLoading">
            <button *ngIf="this.estaAgregando" mat-raised-button color="primary" type="submit"  type="submit"
            [disabled]="!directorForm.valid"
            [ngClass]="{'animate-bounce': directorForm.valid }" (click)="agregarDirector()">
            <mat-icon *ngIf="this.estaAgregando" >lock</mat-icon>
              Agregar
            </button>
            <button *ngIf="this.estaEditando"
            mat-raised-button color="primary" type="submit"  type="submit"
              [disabled]="!directorForm.valid"
              [ngClass]="{'animate-bounce': directorForm.valid }" (click)="editarDirector()">
              <mat-icon *ngIf="!directorForm.valid" >lock</mat-icon>
              Editar
            </button>
          </div>
          <div *ngIf="isLoading">
            Procesando
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade"  id="modalDetalles"
  tabindex="-1" aria-labelledby="modalDetalles" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable  modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5">Detalles del Director</h1>
        <button type="button"  data-bs-dismiss="modal" aria-label="Close">✕</button>
      </div>
      <div class="modal-body">
        <div *ngIf="encargadoSeleccionado" class="px-8 py-4">
          <p class="text-2xl font-bold pb-4 text-slate-600">Datos de Director</p>

          <div class="border-2 border-slate-400 rounded-2xl p-8">

            <table role="table" aria-describedby="tabla encargado" class="[width:100%]">
              <tr class="w-full gap-6" *ngFor="let item of encargadoDetalles; let i=index">
                <th class="w-1/3 h-auto py-0 text-slate-600" *ngIf="item.value != 'Rut Sostenedor'">
                  {{item.value}}
                </th>
                <th class="w-1/3 h-auto py-0 text-slate-600 pt-4" *ngIf="item.value == 'Rut Sostenedor'">
                  {{item.value}}
                </th>
                <td *ngIf="item.value != 'Rut Sostenedor'">
                  {{encargadoSeleccionado[item.key] == null ? "-" : encargadoSeleccionado[item.key]}}
                </td>
                <td class="pt-4" *ngIf="item.value == 'Rut Sostenedor'">
                  {{encargadoSeleccionado[item.key] == null ? "-" : encargadoSeleccionado[item.key]}}
                </td>
              </tr>
            </table>
          </div>
          <div *ngIf="!establecimientosEncargado" class="my-2 border-2 border-yellow-600 rounded-2xl">
            <p class="text-md text-yellow-950 p-8 font-bold opacity-100">Encargado sin establecimientos asignados</p>
          </div>

          <div *ngIf="establecimientosEncargado">
            <p class="text-2xl font-bold py-4 text-slate-600">Encargados asociados ({{establecimientosEncargado.length}})</p>

          <p *ngIf="!establecimientosEncargado || establecimientosEncargado.length == 0"> Sin encargados asociados.</p>
          <div #establecimientos *ngIf="establecimientosEncargado.length > 0">

            <div class="bg-primary-50 flex flex-row gap-2 px-2 mx-4 py-6 border-2 border-slate-400 mb-4 rounded-2xl" *ngFor="let est of establecimientosEncargado">
              <div class="w-1/4 flex flex-col justify-center text-center h-auto">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex h-14 " viewBox="0 0 576 512" fill="rgba(59,113,202, 0.9)"><path d="M288 0H400C408.8 0 416 7.164 416 16V64C416 72.84 408.8 80 400 80H320V95.53L410.3 160H512C547.3 160 576 188.7 576 224V448C576 483.3 547.3 512 512 512H336V400C336 373.5 314.5 352 288 352C261.5 352 240 373.5 240 400V512H64C28.65 512 0 483.3 0 448V224C0 188.7 28.65 160 64 160H165.7L256 95.53V32C256 14.33 270.3 0 288 0V0zM288 192C261.5 192 240 213.5 240 240C240 266.5 261.5 288 288 288C314.5 288 336 266.5 336 240C336 213.5 314.5 192 288 192zM80 224C71.16 224 64 231.2 64 240V304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V240C128 231.2 120.8 224 112 224H80zM448 304C448 312.8 455.2 320 464 320H496C504.8 320 512 312.8 512 304V240C512 231.2 504.8 224 496 224H464C455.2 224 448 231.2 448 240V304zM80 352C71.16 352 64 359.2 64 368V432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V368C128 359.2 120.8 352 112 352H80zM464 352C455.2 352 448 359.2 448 368V432C448 440.8 455.2 448 464 448H496C504.8 448 512 440.8 512 432V368C512 359.2 504.8 352 496 352H464z"/></svg>
                <p class="text-xs align-middle">{{est.dependencia}}</p>
              </div>
              <div>
                <div class="mx-2 w-full">
                  <div class="flex flex-row gap-2 justify-center">
                    <p class="font-bold min-w-20 text-primary-900 align-middle">RUT</p>
                    <p class="w-full text-sm align-bottom pt-1"> {{est.rut}}</p>
                  </div>
                </div>
                <div class="mx-2 w-full">
                  <div class="flex flex-row gap-2 justify-center">
                    <p class="font-bold min-w-20 text-primary-900 align-middle">Nombre</p>
                    <p class="w-full text-sm align-bottom pt-1"> {{est.nombre + " " + est.primerApellido + " " + est.segundoApellido}}</p>
                  </div>
                </div>
                <div class="mx-2 w-full">
                  <div class="flex flex-row gap-2 justify-center">
                    <p class="font-bold min-w-20 text-primary-900 align-middle">Correo</p>
                    <p class="w-full text-sm align-bottom pt-1"> {{est.correo}}</p>
                  </div>
                </div>
                <div class="mx-2 w-full">
                  <div class="flex flex-row gap-2 justify-center">
                    <p class="font-bold min-w-20 text-primary-900 align-middle">Teléfono</p>
                    <p class="w-full text-sm align-bottom pt-1"> {{est.telefono}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
