import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QaGrabacionesSupervisorService } from '../../services/qaGrabacionesSupervisorService';

@Component({
  selector: 'app-rubrica-supervisor',
  templateUrl: './rubrica-supervisor.component.html',
  styleUrls: ['./rubrica-supervisor.component.css']
})
export class RubricaSupervisorComponent {

  rubricaData: any[] = [];
  isLoading: boolean = true; 

  constructor(
    public dialogRef: MatDialogRef<RubricaSupervisorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rutDocente: number; revisor: string },
    protected serviceSupervisor: QaGrabacionesSupervisorService,
  ) {}

  ngOnInit(): void {
    this.cargarRubrica(this.data.rutDocente, this.data.revisor);
    this.rubricaData.forEach(rubrica => {
      rubrica.respuesta = rubrica.respuesta || { idRespuesta: null };
    });
    
  }

  cargarRubrica(rutDocente: number, idRevisor: string): void {
    this.serviceSupervisor.getRubricaPorRutDocenteYRevisor(rutDocente, idRevisor).subscribe(
      (data) => {
        this.rubricaData = data; 
        this.isLoading = false; 
      },
      (error) => {
        console.error('Error al cargar la rúbrica:', error);
        this.isLoading = false;
      }
    );
  }
}
