<mat-card  class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="w-full m-2 p-2 flex flex-col gap-4"  #FILTRO >
      <div class="flex flex-col gap-4 px-2">
        <p class="text-blue-950 text-2xl font-bold">Tickets MDA</p>
        <div class="filtros2 w-full form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
          <div class="flex flex-row gap-1 py-2">
            <mat-icon>filter_list</mat-icon>
            <p class="text-lg font-bold">Filtros</p>
          </div>

          <div class="row" aria-label="sección de inputs">
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
              <mat-form-field class="fullWidth">
                <mat-label>ID Ticket</mat-label>
                <input type="number" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  placeholder="12345678" [(ngModel)]="filtros.idTicket" name="idTicket" />
              </mat-form-field>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
              <mat-form-field class="fullWidth">
                <mat-label>Rut del Consultante</mat-label>
                <input type="number" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  placeholder="Rut sin guión" class="fullWidth" [(ngModel)]="filtros.rut" />

              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-3">
              <mat-form-field class="fullWidth">
                <mat-label class="fullWidth">ID Solicitante</mat-label>

                <input type="number" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  placeholder="9876543210" class="fullWidth" [(ngModel)]="filtros.idSolicitante" />

              </mat-form-field>
            </div>

          </div>

          <div class="d-flex" aria-describedby="footer filtro">
          <div aria-labelledby="botones inf. izq" class="float-left d-flex  align-self-start ">
            <mat-card-actions class="d-flex row">
              <button
                type="button"
                data-te-ripple-init
                (click)="filtrar()"
                [disabled]="this.isLoading"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                <p class="pr-1"></p> Filtrar
              </button>
              <button
                type="button"
                data-te-ripple-init
                (click)="limpiar()"
                [disabled]="this.isLoading"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center ajustar items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                <p class="pr-1"></p> Limpiar
              </button>
            </mat-card-actions>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="table-responsive p-2" aria-label="sección tabla" >
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading" />
    <table class="table table-striped" role="table" aria-label="tabla tickets">
      <thead>
        <tr>
          <th scope="col">{{columnas[0].name}}</th>
          <th scope="col">{{columnas[1].name}}</th>
          <th scope="col">{{columnas[2].name}}</th>
          <th scope="col">{{columnas[3].name}}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="etiqueta-azul" *ngFor="let element of response">

          <td class="negrita-tabla">
            <div style="min-width: 130px;">
              <button data-bs-toggle="modal" [disabled]="this.isLoading" data-bs-target="#modalTicket" type="button"
              [ngClass]="{'border-gray-400 text-gray-600': isLoading}"
                class="inline-block rounded-xl border-2 border-primary px-6 pb-[6px] pt-2 text-[14px] font-bold uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 "
                (click)="obtenerTicketyComentarios(element)">
                {{ element.id}}</button></div>
          </td>
          <td class="negrita-tabla">{{element.subject}}</td>
          <td class="negrita-tabla">{{element.rut}}</td>
          <td class="negrita-tabla">{{element.nombre}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
    [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="Select page of periodic elements">
  </mat-paginator>
</mat-card>

<!-- modal de tickets y comentarios -->
<div class="modal fade" id="modalTicket" tabindex="-1" aria-labelledby="tickets"
  aria-hidden="true" >
  <div class="modal-dialog modal-dialog-scrollable max-w-screen-2xl h-screen">
    <div class="modal-content" *ngIf="ticket">
      <div class="modal-header">
        <div *ngIf="ticket">
          <div class="flex flex-row gap-2">
            <h3 class="modal-title text-2xl pb-2" id="tickets">Ticket #{{ticket.id}} </h3>
            <div *ngIf="ticket.status" class="px-2 my-2 border-slate-700 border-2 rounded-2xl bg-slate-200">Estado: {{ticket.status}} </div>
          </div>
          <p class="text-md">{{ticket.subject}} </p>
        </div>
        <button type="button" data-bs-dismiss="modal" aria-label="Close"
          class="rounded-md p-2 inline-flex text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
          <span class="sr-only">Close menu</span>
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="modal-body  mx-4">
        <div #descripcionticket class="mb-2 border-slate-300 rounded-lg p-4 border-2 border-l-8 border-l-sky-700">
          <div class="flex flex-row gap-1">
            <img
              src="../../../../assets/images/content/av.png"
              class="object-cover w-20 mx-4 py-1 rounded-full shadow-lg flex flex-row "
              alt="Avatar" />
              <div *ngIf="docenteTicket[0]" class="flex flex-col gap-1">
                <div class="font-bold text-lg text-sky-900 flex flex-row">
                  {{ticket.nombre}}
                </div>

                <p>Rut <b *ngIf="ticket.rut>0">{{ticket.rut}}</b> <b *ngIf="ticket.rut==0">no encontrado en el ticket.</b></p>
                <div class="text-sky-950 flex flex-row gap-1 text-md justify-center">
                  <div class="text-sky-950 flex flex-row gap-1 text-md justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"  zoomAndPan="magnify" viewBox="0 0 30 30.000001" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="id1"><path d="M 3.460938 6.5625 L 26.539062 6.5625 L 26.539062 24.707031 L 3.460938 24.707031 Z M 3.460938 6.5625 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#id1)"><path fill="rgb(6.269836%, 5.879211%, 5.099487%)" d="M 24.230469 11.101562 L 15 16.769531 L 5.769531 11.101562 L 5.769531 8.832031 L 15 14.503906 L 24.230469 8.832031 Z M 24.230469 6.5625 L 5.769531 6.5625 C 4.492188 6.5625 3.472656 7.578125 3.472656 8.832031 L 3.460938 22.441406 C 3.460938 23.695312 4.492188 24.707031 5.769531 24.707031 L 24.230469 24.707031 C 25.507812 24.707031 26.539062 23.695312 26.539062 22.441406 L 26.539062 8.832031 C 26.539062 7.578125 25.507812 6.5625 24.230469 6.5625 " fill-opacity="1" fill-rule="nonzero"/></g></svg>
                    <p *ngIf="ticket.correo.length > 3">{{ticket.correo}}</p>
                    <p *ngIf="ticket.correo.length <= 3">{{docenteTicket[0].email}}</p>
                    <p *ngIf="ticket.correo.length <= 3 && docenteTicket[0].email.length <= 3" class="text-red-600"> Sin correo identificado</p>
                  </div>
                  <p class="text-sky-950 flex flex-row gap-1 text-md justify-center" *ngIf="ticket.telefono1>0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-phone-fill" viewBox="0 0 16 16"> <path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"/> </svg>
                    {{ticket.telefono1}}
                  </p>
                  <p class="text-sky-950 flex flex-row gap-1 text-md justify-center" *ngIf="ticket.telefono2>0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-phone-fill" viewBox="0 0 16 16"> <path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"/> </svg>
                    {{ticket.telefono2}}
                  </p>
                </div>
              </div>
          </div>
          <p class="m-2 px-4 py-2" #descriptionTicket> </p>
        </div>
        <div class="flex flex-row gap-2">
          <div #detallesTicket class="xs:w-full md:w-1/3 border-2 border-slate-300 rounded-lg px-4 py-6 h-full overflow-auto">
            <div class="px-4 pb-2">
              <h3 class="py-4">Detalles Ticket</h3>
            <div class="text-slate-600  my-2 border-2 border-l-8 border-l-sky-700 rounded-xl p-4 ">
              <div class="flex flex-row gap-2">
                <p class="font-bold">Creación de ticket</p>
                <p>{{fecha(ticket.created_at)}} </p>
              </div>
              <div class="flex flex-row gap-2">
                <p class="font-bold">Últ. Actualización </p>
                <p>{{fecha(ticket.updated_at)}} </p>
              </div>
            </div>
            <h4>Canal de comunicación</h4>
            <p class="px-4" *ngIf="docenteTicket[0]">{{docenteTicket[0].via}} </p>
            <h4>Satisfacción de la respuesta</h4>
            <p class="px-4" *ngIf="!['good', 'bad'].includes(satisfaccion(ticket.satisfaction_rating).score)">{{satisfaccionTraducida(ticket.satisfaction_rating)}} </p>
            <p class="p-4 rounded-xl" *ngIf="satisfaccion(ticket.satisfaction_rating).comment"
            [ngClass]="{'bg-green-200': satisfaccion(ticket.satisfaction_rating).score =='good','bg-red-200': satisfaccion(ticket.satisfaction_rating).score =='bad'}">{{satisfaccion(ticket.satisfaction_rating).comment}} </p>
            </div>
          </div>

          <div #detallesComentarios class="xs:w-full md:w-2/3 border-2 border-slate-300 rounded-lg  p-4  h-full overflow-auto">
            <h3 class="pb-2">Comentarios</h3>

            <div *ngIf="!comentarios || comentarios.length == 0" class="py-4 h-40">
              Sin comentarios
            </div>
            <div *ngIf="comentarios.length>0">
              <div *ngFor="let com of comentarios" >
                <div *ngIf="com.via" class="w-full border-slate-300 border-2 rounded-lg p-4 my-4 border-l-8 border-l-sky-700">
                  <div class="text-slate-600">{{fecha(com.created_at)}}</div>
                  <div class="text-slate-400">Autor: {{com.autorComentario}}</div>
                  <div class="py-4">{{com.body}}</div>
                  <div class="py-4"><b>Tipo de Acción:</b> {{catalogarEmail(com.via, com.autorComentario)}}</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="modal-content shadow-lg h-screen rounded-lg" *ngIf="!ticket">
      <div class="modal-header animate-pulse  rounded-lg">
        <div class="h-20 w-full bg-gray-400 rounded-lg"></div>
      </div>
      <div class="modal-body h-60">
        <div class="mb-2 animate-pulse h-40 border-slate-300 rounded-lg p-4 border-2 border-l-8 border-l-sky-700 bg-gray-400 " ></div>
        <div class="flex flex-row gap-2 h-full">
          <div class="bg-gray-300 animate-pulse xs:w-full md:w-1/3 border-2 border-slate-300 rounded-lg px-4 py-6 h-60 overflow-auto"></div>
          <div class="bg-slate-300 animate-pulse xs:w-full md:w-2/3 border-2 border-slate-300 rounded-lg  p-4  h-96 overflow-auto">
      </div>
    </div>
  </div>
</div>


