<div mat-dialog-content>
  <ng-container *ngIf="isLoading; else rubricaContent">
    <p>Cargando rúbrica...</p>
  </ng-container>

  <ng-template #rubricaContent>
    <p><strong>Rut Docente:</strong> {{ data.rutDocente }}</p>
    <p><strong>Revisor:</strong> {{ data.revisor }}</p>
  
    <div class="rubrica-container">
      <table mat-table [dataSource]="rubricaData" class="mat-elevation-z8">
        <!-- Columna para Pregunta -->
        <ng-container matColumnDef="pregunta">
          <th mat-header-cell *matHeaderCellDef> Pregunta </th>
          <td mat-cell *matCellDef="let rubrica"> {{ rubrica.pregunta }} </td>
        </ng-container>
    
        <!-- Columna para Alternativas -->
        <ng-container matColumnDef="alternativas">
          <th mat-header-cell *matHeaderCellDef> Alternativas </th>
          <td mat-cell *matCellDef="let rubrica">
            <form>
              <div *ngFor="let alternativa of rubrica.alternativas">
                <label>
                  <input
                    type="radio"
                    [name]="'respuesta_' + rubrica.rubricaId"
                    [value]="alternativa.id"
                    [(ngModel)]="rubrica.respuesta.idRespuesta"
                    [disabled]="true" 
                  />
                  {{ alternativa.respuesta }}
                </label>
              </div>
            </form>
          </td>
        </ng-container>
    
        <!-- Fila de encabezado -->
        <tr mat-header-row *matHeaderRowDef="['pregunta', 'alternativas']"></tr>
        <!-- Fila de datos -->
        <tr mat-row *matRowDef="let rubrica; columns: ['pregunta', 'alternativas']"></tr>
      </table>
    </div>
    
  
    <ng-template #noData>
      <p>No se encontraron datos para esta combinación.</p>
    </ng-template>
  </ng-template>
  
  
  
</div>
