import { environment } from "src/environments/environment";

export class Constants {

    private static PROTOCOL: string = environment.protocol + "://";
    private static DOMINIO_BASE: string = environment.protocol + "://" +environment.host;
    private static HOST: string = environment.host;

    private static BASE_MANTENEDOR_USUARIO =  this.PROTOCOL + this.HOST + "/registro";
    private static USUARIOS=  this.BASE_MANTENEDOR_USUARIO  + "/usuarios/";
    public static BASE_OFICIOS: string = Constants.DOMINIO_BASE + "/registro/oficios";
    public static BASE_MANTENEDOR_FEED: string = Constants.DOMINIO_BASE + "/noticias/";
    public static BASE_EVENTOS: string = Constants.DOMINIO_BASE + "/eventos";
    static BASE_CIERRE_PROCESOS: string = Constants.DOMINIO_BASE + "/cierre-procesos" ;
    static BASE_PORTAFOLIO: string = Constants.DOMINIO_BASE + "/portafolio" ;

    public static URL_SERVICE = {
        BASE_ENCARGADO_EVALUACION: this.PROTOCOL + this.HOST + "/registro",
        BASE_DOCENTE: this.PROTOCOL + this.HOST + "/docentes-historico",
        BASE_REGISTRO_DOCENTE: this.PROTOCOL + this.HOST + "/registrar",
        BASE_MANTENEDOR_USUARIO: this.PROTOCOL + this.HOST + "/registro/mantenedor-usuario",
        BASE_ARCHIVOS: this.PROTOCOL + this.HOST + "/archivos",
        BASE_FICHA_DOCENTE: this.PROTOCOL + this.HOST + "/docente",
        BASE_EVALUACIONES_HISTORICAS_EXTENDIDA: this.PROTOCOL + this.HOST + "/docentes-historico",
        BASE_TOKEN_DOCENTE: this.PROTOCOL + this.HOST + "/token",
        BASE_ARCHIVO_DOCENTE: this.PROTOCOL + environment.hostdm + "/notificacion-inscripcion",
        BASE_USUARIOS_OPERACIONES: this.PROTOCOL + this.HOST + "/usuarios-operaciones",
        BASE_CARGAS_MASIVAS: this.PROTOCOL + this.HOST + "/cargas-masivas",
        BASE_TICKETS: this.PROTOCOL + this.HOST + "/tickets",
        BASE_LLAMADAS: this.PROTOCOL + this.HOST + "/historial-llamadas",
        BASE_PORTAFOLIO_ACTUAL: this.PROTOCOL + this.HOST + "/portafolio-actual",
        BASE_INFORMES: this.PROTOCOL + this.HOST + "/informes",
        BASE_GRABACIONES: this.PROTOCOL + this.HOST + "/grabaciones",
        BASE_REPORTE: this.PROTOCOL + this.HOST + "/reportes",
        BASE_DIRECTOR: this.PROTOCOL + this.HOST + "/registro/director",

        RECURSO_REPOSICION:{
            FILTER: "/filtrar",
            TEST: "/test",
            AGREGAR: "/agregar",
            DOCENTE: "/datadocente",
            GETBYRUT: "/getbyrut",
            EDITAR: "/editar",
            UPLOAD_FILE: "/subir",
            DESCARGA: "/descargar",
        },
        MANTENEDOR_USUARIOS:{
            LISTAR: this.USUARIOS,
            CREATE: this.USUARIOS,
            UPDATE: this.USUARIOS,
            DELETE: this.BASE_MANTENEDOR_USUARIO  + "/usuarios",
            DOWNLOAD: this.USUARIOS + "descarga",
            ROLELIST: this.USUARIOS + "tipo-usuarios",
        },
        ENCARGADOS_EVALUACION: {
            LISTAR: "/encargado-evaluacion/",
            DESCARGAR: "/encargado-evaluacion/csv",
            FIND: "/encargado-evaluacion/",
            SAVE: "/encargado-evaluacion",
            UPDATE: "/encargado-evaluacion",
            DELETE: "/encargado-evaluacion/",
        },
        DOCENTES: {
            LISTAR: "/",
            DESCARGAR: "/descarga",
            DESCARGA_INFORME_DOCENTE: "/docente/{rut}/informes/agno/{agno}",
            EVALUACIONES_HISTORICAS: "/evaluaciones-historicas/",
            DESCARGA_ARCHIVO_SUSPENSION: "/docente/archivo-suspension",
        },
        ESTABLECIMIENTO: {
            LISTAR: "/establecimiento/getEstablecimientoList"
        },
        SECTOR: {
            LISTAR: "/sector/getSectorList"
        },
        SOSTENEDOR: {
            LISTAR: "/sostenedor/"
        },
        REPRESENTANTES:{
            LISTAR: "/representante-legal/",
            EMPRESA: "/representante-legal/nombre-empresa/",
            AGREGAR: "/representante-legal/",
            EDITAR: "/representante-legal/",
            ELIMINAR: "/representante-legal",
            DESCARGAR: "/representante-legal/csv"
        },
        DEPENDENCIA: {
            LISTAR: "/dependencia/getDependenciaList"
        },
        REGION: {
            LISTAR: "/region/getRegionList"
        },
        FICHA_DOCENTE: {
          LISTAR: "/",
          EDITAR_DOCENTE: "/",
          SUSPENDER_EXIMIR: "/suspendereximir/",
          LISTAR_ESTABLECIMIENTOS: "/datos-formulario",
      },
        EVALUACION_HISTORICA_EXTENDIDA: {
          LISTAR: "/ev-historicas-extendidas",
          DESCARGAR: "/ev-historicas-extendidas/descarga",
          FILTROS: "/ev-historicas-extendidas/filtros"
        },
        GESTOR_COGNITO : {
            GRUPOS: "/filters",
            BASE: "/",
        },
        GESTOR_COGNITO_MDA : {
          BASE: "/mda",
      },
        OFICIOS: {
          FILTROS: "/filtros",
        },
        CARGAS_MASIVAS: {
          VERIFICAR_HEADERS: "/cargas-masivas/verificar-headers",
          CARGA_DATOS: "/cargas-masivas/carga-datos",
          LISTAR: "/cargas-masivas/mostrar-datos",
          CARGA_DATOS_TEMP: "/cargas-masivas/carga-datos-temp",
        },
        HISTORIAL_LLAMADAS: {
            LISTAR: "/historial-llamadas/listar",
            DESCARGAR: "/historial-llamadas/descarga",
          },
        DESCARGA_INFORMES: {
          DESCARGA: "/archivo/descarga",
          INFORME: "/archivo/descarga/informe-rbd-sostenedor"
        },

        DESCARGA_REPORTE: {
            DESCARGA: "/descarga",
            REPORTESBBDD: "/reportesporbbdd"
        },
        RECEPCION_BULTO: {
          LISTAR: "/recepcion/listar",
          AGREGAR: "/recepcion/agregar",
          ELIMINAR: "/recepcion/eliminar",
          EDITAR: "/recepcion/editar",
          DESCARGAR: "/recepcion/descargar",
          CHECKSEGUIMIENTO: "/recepcion/checkNumeroSeguimiento",
          CHECKSEGUIMIENTOBULTO: "/recepcion/checkNumeroSeguimientoBulto",
          CHECKFOLIO: "/recepcion/checkFolio",
          CHECKCAJA: "/recepcion/checkCaja"
        },
        APERTURA_CAJA: {
          DESCARGAR: "/apertura/caja/descargar"

        },
        DIRECTOR: {
          LISTAR: "/",
          DESCARGAR: "/descargar",
          AGREGAR: "/agregar",
          EDITAR: "/editar",
          ELIMINAR: "/eliminar",
          NOMBRE_ESTABLECIMIENTO: "/nombre-establecimiento",
          DIRECTOR_ESTABLECIMIENTO: "/director-establecimiento",
          GET_ENCARGADOS: "/encargados"
        }
    }

}
