import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FiltroApertura } from '../models/filtroApertura';
import { PaginationOptions } from 'src/app/registros/models/paginationOptions';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AperturaCajaService } from '../services/apertura-caja.service';
import { RegistroApertura } from '../models/registroApertura';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/base/services/auth.service';
import { Constants } from 'src/app/base/constants/constants';
import { Toast } from 'src/app/base/services/toast';
import { CajaBulto } from '../models/CajaBulto';
import { NuevaCaja } from '../models/nuevaCaja';
import { AutorizacionesService } from 'src/app/base/services/autorizaciones.service';
import { ModulosAutorizacion } from 'src/app/base/models/ModulosAutorizacion';


const estadosCajaGrabaciones = [
  { nombre: 'Caja Abierta', id:0 },
  { nombre: 'Caja Sellada', id:1 },
];
@Component({
  selector: 'app-apertura-caja',
  templateUrl: './apertura-caja.component.html',
  styleUrls: ['./apertura-caja.component.css']
})
export class AperturaCajaComponent implements OnInit {

  filtros: FiltroApertura = new FiltroApertura();
  dataSource: MatTableDataSource<RegistroApertura> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('abrirModalCaja') abrirModalCaja: ElementRef;
  permisoEdicion!: boolean;
  isLoading!: boolean;
  esconderAnimacion!: boolean;
  faSpinner = faSpinner;
  estaDescargandoExcel!: boolean;
  pageIndex: number;
  length: number;
  options: PaginationOptions = new PaginationOptions();
  estados!: {nombre: string, id: number}[];
  minDate = new Date("Saturday, 1 March 2024 0:00:00");
  today = new Date();
  operadores!: string[];
  response!: any;
  bultoInicialForm!: FormGroup;
  modoSD!: boolean;
  cajaEnOperacion!: RegistroApertura;
  roles: string[] = ['ROLE_ADMIN','ADM_REGISTRO','ADM_GRABACIONES'];

  folioCajaLibre!: boolean;
  folioBultoLibre!: boolean;
  bultoControl: FormControl = new FormControl('');
  listaBultos!: string[];

  archivoAnimacionPrincipal = "startfichaseguimiento.json";
  archivoAnimacionAccion = "qrscan.json";
  bloquearCantidadSD!: boolean;
  modoEdicionCaja: boolean;

  authModulos: ModulosAutorizacion = new ModulosAutorizacion();
  permisosCRUD!: boolean;
  permisosDescarga!: boolean;
  permisosSelladoCaja!: boolean;
  permisosReaperturaCaja!: boolean;

  constructor(
    private servicio: AperturaCajaService,
    private errorMensaje: ErrorMensajeService,
    private fb: FormBuilder,
    private authService: AuthService,
    private permisoPara: AutorizacionesService
  ){
    const autorizaciones = this.authModulos.moduloTracklog[3].authorizationRoles;
    this.permisosDescarga = this.permisoPara.containRol(autorizaciones['DOWNLOAD']);
    this.permisosSelladoCaja = this.permisoPara.containRol(autorizaciones['SELLAR'])
    this.permisosReaperturaCaja = this.permisoPara.containRol(autorizaciones['REABRIR'])
    //this.permisosCRUD = this.permisoPara.containRol(["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES", "EJECUTIVO_GRABACIONES", "OPERADOR_GRABACIONES"]);
    this.permisosCRUD = this.permisoPara.containRol(autorizaciones['CREATE'])
    //this.permisoEdicion = this.authService.hasRole(["ADM_REGISTRO","ROLE_ADMIN","ADM_GRABACIONES","OPERADOR_GRABACIONES"]);
    this.permisoEdicion = this.permisoPara.containRol(autorizaciones['UPDATE'])
    this.bultoInicialForm = this.fb.group({
      idCaja: [''],
      idBulto: [''],
      codigoBulto: this.bultoControl,
      codigoCaja: ['', [Validators.required, Validators.pattern(/^BB[0-9][0-9][0-9]$/)]],
      manifiesto: [false],
      cantidadSD: [0, [Validators.required, Validators.min(0), Validators.pattern("^[0-9]*$")]]
    });
  }

  ngOnInit() {
    this.estados = estadosCajaGrabaciones;
    this.limpiar();
    this.obtenerListaBultos();
    this.bloquearCantidadSD = true;
    this.modoEdicionCaja = false;
    this.bultoInicialForm?.get('codigoCaja')?.valueChanges.subscribe(value => {
      const regex = /^BB[0-9][0-9][0-9]$/;
      value = value?.trim()?.toUpperCase();
      if (regex.test(value)) this.folioCajaDisponible(value);
      this.bultoInicialForm.patchValue({ codigoCaja: value}, { emitEvent: false });
    });

    this.bultoInicialForm?.get('codigoBulto')?.valueChanges.subscribe(value => {
      const regex = /^BB[0-9][0-9][0-9][0-9]$/;
      if (!value) return;
      if(regex.test(value)) this.folioBultoDisponible(value);
      else {
        this.bultoInicialForm.patchValue({cantidadSD: null},{emitEvent: false})
      }
    });

    this.bultoControl?.valueChanges.subscribe(value =>
      {
        if (!value || !value?.trim()) return;
        value = value.trim().toUpperCase();
        this.bultoControl.patchValue(value, {emitEvent: false});
        if (!this.listaBultos?.includes(value) || this.modoEdicionCaja) return;
        this.servicio.obtenerDiferenciaSDdeBulto(value)
        .subscribe(
        {
          next: (value: any) => {
            this.bultoInicialForm.patchValue({cantidadSD: value},{emitEvent: false})
            this.bloquearCantidadSD = value != null;

          },
          error: (err: any) => {
            this.informarError(err, "obtener la diferencia de sd del bulto");
          }
        });

      }
      );
  }

  ngAfterViewInit() {
    if(!this.paginator) return;
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;
  }

  // Manejar el envío del formulario
  crearCaja() {
    if (this.bultoInicialForm.valid) {
      this.modoEdicionCaja = false;
      let caja: NuevaCaja = new NuevaCaja();
      const cajaForm = this.bultoInicialForm.value;
      caja.bulto = cajaForm.codigoBulto;
      caja.folio = cajaForm.codigoCaja?.toUpperCase();
      caja.capacidad = cajaForm.cantidadSD;
      caja.estado = 0;
      caja.operador = this.authService.getEmailUser();
      caja.manifiesto = cajaForm?.manifiesto == true;

      this.servicio.crearCaja(caja)
      .subscribe({
        next: (cajaCreada: any | RegistroApertura) => {
          Swal.fire("Caja Creada","Ahora puede agregar las tarjetas SD", "success");
          caja.idCaja = cajaCreada.idCaja;
          this.cajaEnOperacion = cajaCreada;
          this.modoSD = true;
          this.bloquearCantidadSD = true;
          this.bultoInicialForm.reset();
        },
        error: (err: any) => {
          this.informarError(err, "crear esta caja");
        }
      });

    } else {
      Swal.fire("Formulario inválido", "Revise sus datos para registrar esta caja.", "warning");
      this.modoSD = false;
    }
  }

  seleccionarCaja(caja: RegistroApertura) {
    this.modoSD = true;
    this.cajaEnOperacion = caja;
  }

  obtenerListaBultos() {
    this.servicio.obtenerBultos()
    .subscribe(
      {
        next: (bultos: any | string[]) => {
            this.listaBultos = bultos;
        },
        error: (err: any) => {
            this.listaBultos = null;
            this.informarError(err, "obtener la lista de bultos");
        },
      }
    )
  }

  folioCajaDisponible(folio: string) {
    this.servicio.folioCajaDisponible(folio)
    .subscribe(
      {
        next: (value: any | boolean) => {
            this.folioCajaLibre = value;
        },
        error: (err: any) => {
            this.informarError(err, "validar el folio de caja");
            this.folioCajaLibre = false;
        },
      }
    );
  }

  folioBultoDisponible(folio: string) {
    this.servicio.folioBultoDisponible(folio)
    .subscribe(
      {
        next: (value: any | boolean) => {
            this.folioBultoLibre = value;
        },
        error: (err: any) => {
            this.informarError(err, "validar el folio del bulto");
            this.folioBultoLibre = false;
            this.bultoInicialForm.patchValue({cantidadSD: null},{emitEvent: false})
        },
      }
    );
  }

  cleanModalCaja() {
    this.bloquearCantidadSD = false;
    this.bultoInicialForm?.reset();
    this.modoEdicionCaja = false;
  }

  eliminar(caja: RegistroApertura) {
    if (!caja) {
      return;
    }
    Swal.fire({
      title: '¿Desea eliminar esta caja y todos sus tarjetas SD registradas?',
      text: 'Este proceso no se puede deshacer',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#d9534f',
      cancelButtonColor: '#0275d8',

    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminarCaja(caja)
      }
    })

  }

  eliminarCaja( caja: RegistroApertura) {
    this.servicio.borrarCaja(caja.idCaja, caja.folio)
    .subscribe(
      {
        next: (value: any ) =>{
            Swal.fire("Caja eliminada","Registros eliminados", "success");
            this.limpiar();
        },
        error: (err: any) => {
            this.informarError(err, "eliminar la caja y sus SD");
        },
      });
  }

  volverMenuPpal() {
    this.modoSD = false;
    this.cajaEnOperacion = null;
    this.limpiar();
  }

  abrirModalEdicionCaja(caja: RegistroApertura) {
    if (!this.permisoEdicion) {
      Swal.fire("Edición de Caja","Sin permisos para esta operación.","warning");
      return;
    }
    const bultoCapacidad = JSON.parse(caja.bultos)?.find((item: CajaBulto) => item.idBulto == caja.idBulto)?.capacidad;
    const primerBulto: CajaBulto = this.jsonBultos(caja.bultos)[0];
    this.bultoInicialForm.patchValue({
      idCaja: caja.idCaja,
      idBulto: primerBulto?.idBulto,
      codigoCaja: caja.folio,
      codigoBulto: primerBulto?.folioBulto,
      manifiesto: primerBulto?.manifiesto == true,
      cantidadSD: !bultoCapacidad ? 0: bultoCapacidad
    }, {emitEvent:false});
    this.modoEdicionCaja = true;
    this.bloquearCantidadSD = true;
    this.abrirModalCaja.nativeElement.click();
  }

  editarCaja() {
    let caja: RegistroApertura = new RegistroApertura();
      const cajaForm = this.bultoInicialForm.value;
    this.servicio.editarCaja(cajaForm.idCaja, cajaForm.idBulto, cajaForm.manifiesto == true)
    .subscribe({
      next: (value:any) => {
          Swal.fire("Edición de la caja", "Todo correcto", "success");
          this.limpiar();
      },
      error: (err : any) => {
        this.informarError(err, "editar su caja");
      },
    }
    )
  }

  filtrar(){
    this.iniciarConsulta();
    this.servicio.getCajas(this.filtros, this.options)
    .subscribe(
      {
        next: (cajas: any) => {
          this.dataSource = new MatTableDataSource<RegistroApertura>(cajas.list);
          this.response = cajas.list;
          this.pageIndex = (cajas.pageNum - 1);
          this.length = cajas.total;
          this.finalizarConsulta();
        },
        error: (err: any) => {
          this.informarError(err, 'obtener las cajas');
        },
      });
  }

  jsonBultos( bultos: string ): any {
    const json = JSON.parse(bultos.replace("'",'"'));
    if (json?.length == 1 && json[0].idBulto == null) return null
    else return json;
  }

  estadoCaja(id:number) {
    return this.estados.find((item: any) => item.id == id).nombre;
  }

  iniciarConsulta() {
    this.isLoading = true;
    this.esconderAnimacion = false;
  }

  finalizarConsulta() {
    this.isLoading = false;
    setTimeout(() => {
      this.esconderAnimacion = true;
    }, 2000);
  }

  limpiar(){
    this.filtros = new FiltroApertura();
    this.filtrar();
  }

  descargar(){
      const url = `${Constants.URL_SERVICE.BASE_GRABACIONES + Constants.URL_SERVICE.APERTURA_CAJA.DESCARGAR}`;
      const filename = "listaCajas.csv"
      this.estaDescargandoExcel = true;

      this.servicio.descargarCajas(url, this.filtros)
      .subscribe(
        {
          next: (response: any) => {
            Toast.fire(
              "Descarga de fichas de seguimiento",
              "Descarga finalizada",
              "success"
            );
            this.servicio.downloadBlob(filename, response);
            this.estaDescargandoExcel = false;
          }, error: (err: any) => {
            this.informarError(err, 'descargar la apertur de cajas');
            this.estaDescargandoExcel = false;
          }
        });
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.filtrar();
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    const date = event.value;
    if (date) {
      const formattedDate = formatDate(date, 'dd-MM-yyyy', 'en-US');

      const storageDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
    }
  }

  informarError(err: any, contexto: string) {
    this.errorMensaje.show(err, contexto).then((result:any) => {
      if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
        this.finalizarConsulta();
      }
    });
  }

  containRol() {
    return this.authService.containRol(this.roles);
  }

  sellar(id: number, folio: string){
    this.servicio.cantidadSDCaja(id).subscribe({
      next:(response: any) =>{
        if(response == 0){
          Swal.fire({
            title: '¿Desea sellar la caja '+folio +'?',
            text: 'Este proceso no se puede deshacer',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sellar',
            confirmButtonColor: '#d9534f',
            cancelButtonColor: '#0275d8',

          }).then((result) => {
            if (result.isConfirmed) {
              this.servicio.sellarCaja(id).subscribe({
                next:(response: any) => {
                  Swal.fire("Caja Sellada",response.status,"success");
                  this.filtrar();


                },
                error:(err:any) => {
                  this.errorMensaje.show(err, "sellar caja");

                }
              });
            }
          })

        }
        else{
          Swal.fire("Caja Incompleta",response.status,"warning");
        }
      },
      error:(err: any) =>{
        this.errorMensaje.show(err, "buscar contenido caja");
      }
    })
  }

  abrirCaja(id: number){
    if(this.containRol()){
      this.servicio.abrirCaja(id).subscribe({
        next:(response: any) => {
          Swal.fire("Caja Abierta",response.status,"success");
          this.filtrar();


        },
        error:(err:any) => {
          this.errorMensaje.show(err, "Abrir Caja");

        }
      });
    }
    else{
      Swal.fire({
        title: 'No se puede reabrir',
        text: 'Este proceso solo lo puede realizar un Administrador',
        icon: 'warning',
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#d9534f',
        cancelButtonColor: '#0275d8',

      })

    }
    }
}
