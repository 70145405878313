export class DirectorFilter {
  rutDirector: number;
  rbd: number;
  dependencia: number;
  region: number;
  rutSostenedor: number;
  rutEncargado: number;

  constructor(){
      this.rutDirector = null;
      this.rbd = null;
      this.dependencia = null;
      this.region = null;
      this.rutSostenedor = null;
      this.rutEncargado = null;

  }
}
