import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-supervisor-table',
  templateUrl: './supervisor-table.component.html',
  styleUrls: ['./supervisor-table.component.scss'],
})
export class SupervisorTableComponent {
  @Input() individualRevisions: any[] = [];
  @Input() groupedRevisions: any[] = [];
  @Input() isLoadingIndividual: boolean = false;
  @Input() isLoadingGrouped: boolean = false;
  

  @Output() pageChange = new EventEmitter<any>();
  
  handlePageChange(source: string, event: any): void {
    this.pageChange.emit({ source, data: event });
  }

}
