import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css'],
})
export class LoadingSpinnerComponent {
  @Input() text: string = "Cargando...";
  @Input() subtitle: string = "Esto puede tardar unos segundos...";
}
