import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {QaGrabacionesSupervisorService} from "../../../services/qaGrabacionesSupervisorService";
import { DetalleRevisionSupervisorComponent } from '../../detalle-revision-supervisor/detalle-revision-supervisor.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-individual-revisions-table',
  templateUrl: './individual-revisions-table.component.html',
  styleUrls: ['./individual-revisions-table.component.scss'],
})
export class IndividualRevisionsTableComponent {
  @Input() individualRevisionsResponse: any;
  @Output() pageChange = new EventEmitter<any>(); // Emisor de evento

  revisions: any[] = [];
  total: number = 0;
  currentPage: number = 1;
  pageSize: number = 10;
  pageSizeOptions: number[] = [5, 10, 30, 50]; 
  reloadDataAfterRevisionDetailsClosed: boolean = true;

  constructor(
    protected service: QaGrabacionesSupervisorService,
    public dialog: MatDialog
  ){}

  ngOnInit(): void {
    if (this.individualRevisionsResponse) {
      this.processResponse();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['individualRevisionsResponse'] && this.individualRevisionsResponse) {
      this.processResponse();
    }
  }

  private processResponse(): void {
    this.revisions = this.individualRevisionsResponse.revisiones || [];
    this.total = this.individualRevisionsResponse.totalElements || 0;
    this.currentPage = this.individualRevisionsResponse.pageNum || 1;
    this.pageSize = this.individualRevisionsResponse.pageSize || 10;
  }

  onPageChange(event: any): void {
    this.pageChange.emit({
      page: event.pageIndex + 1, 
      size: event.pageSize, 
      pageSizeOptions: this.pageSizeOptions
    });
  }
  
  verDetalles(revisionId: number) {
        const dialogRef = this.dialog.open(DetalleRevisionSupervisorComponent, {
          width: 'auto',
          height: 'auto',
          maxWidth: '900px',
          data: { id: revisionId },
          disableClose: true
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if(this.reloadDataAfterRevisionDetailsClosed){
            this.processResponse();
          }
        });
    }
}
