import {Component, OnInit} from "@angular/core";
import {QaGrabacionesSupervisorService} from "../services/qaGrabacionesSupervisorService";
import {PaginationOptions} from "../../../models/paginationOptions";
import {AlertsService} from "../../../../base/services/alerts.service";
import {estadosRevisionCorreccion} from "../constants/EstadosRevisionCorreccion";
import {RevisionFilterSupervisor} from "./filters/revision-filter-supervisor";

@Component({
  selector: 'app-qa-grabaciones-supervisor',
  templateUrl: './qa-grabaciones-supervisor.component.html',
  styleUrls: ['./qa-grabaciones-supervisor.component.css']
})
export class QaGrabacionesSupervisorComponent implements OnInit {

  isLoadingIndividual: boolean = false;
  isLoadingGrouped: boolean = false;

  //filtros
  estadosFiltro = estadosRevisionCorreccion;
  estadosFiltroFiltrados: any[] = [];

  // Datos de revisiones
  revisiones = {
    totalRevisiones: 0,
    totalProcesadas: 0,
    totalPendientes: 0
  };

  groupedRevisions: any[] = [];
  individualRevisions: any[] = [];

  /*Variables de paginado*/
  paginationOptionsIndividual: PaginationOptions = new PaginationOptions();
  paginationOptionsAgrupadas: PaginationOptions = new PaginationOptions();
  pageIndex: number = 0;
  length: number = 0; // Total de elementos
  pageSize: number = 10; // Tamaño de página
  pageSizeOptions: number[] = [5, 10, 30, 50]; // Opciones de tamaño de página
  filtros: RevisionFilterSupervisor = new RevisionFilterSupervisor();

  constructor(
    protected alertService: AlertsService,
    protected service: QaGrabacionesSupervisorService,
  ) {}

  ngOnInit(): void {
    this.estadosFiltroFiltrados = Object.values(this.estadosFiltro).filter(estado => estado.id !== 6);

    this.cargarRevisionesAgrupadas()
    this.cargarRevisionesIndividuales()
    this.cargarDatosRevisiones()
  }

  cargarDatosRevisiones() {
    this.service.getDatosRevisiones().subscribe({
      next: (data) => {
        if (data && data.revisiones) {
          this.revisiones = data.revisiones;
        }
      },
      error: (error) => {
        this.alertService.showExceptionAlert(error);
        console.log(error);
      }
    });
  }

  cargarRevisionesAgrupadas(paginationData?: { page: number; size: number ; pageSizeOptions: number[]}): void {
    this.isLoadingGrouped = true;
    
    if (paginationData) {
      this.paginationOptionsAgrupadas.page = paginationData.page || 1; 
      this.paginationOptionsAgrupadas.size = paginationData.size || this.pageSize;
      this.pageSizeOptions = paginationData.pageSizeOptions; 
    }
  
    this.service.getListaRevisionesAgrupadasPorRut(this.paginationOptionsAgrupadas, this.filtros).subscribe({
      next: (response) => {
        this.groupedRevisions = response || {};
        this.length = response.total || 0; 
        this.pageIndex = (response.pageNum || 1) - 1; 
        this.pageSize = response.pageSize || this.pageSize;
        this.isLoadingGrouped = false;
      },
      error: (error) => {
        this.alertService.showExceptionAlert(error);
        console.error(error);
        this.isLoadingGrouped = false;
      },
    });
  }

  cargarRevisionesIndividuales(paginationData?: { page: number; size: number ; pageSizeOptions: number[]}) {
    this.isLoadingIndividual = true;
    if (paginationData) {
      this.paginationOptionsIndividual.page = paginationData.page || 1; 
      this.paginationOptionsIndividual.size = paginationData.size || this.pageSize;
      this.pageSizeOptions = paginationData.pageSizeOptions; 
    }
    this.service.getListaRevisionesIndividuales(this.paginationOptionsIndividual, this.filtros).subscribe({
      next: (response) => {
        this.individualRevisions = response || {};
        this.length = response.total || 0; 
        this.pageIndex = (response.pageNum || 1) - 1; 
        this.pageSize = response.pageSize || this.pageSize;
        this.isLoadingIndividual = false;
      },
      error: error => {
        this.alertService.showExceptionAlert(error);
        console.log(error)
        this.isLoadingIndividual = false;
      }
    })
  }

  onPageChange(event: { source: string; data: any }): void {
    if (event.source === 'groupedRevisions') {
      this.cargarRevisionesAgrupadas(event.data);
    } else if (event.source === 'individualRevisions') { 
      this.cargarRevisionesIndividuales(event.data);
    }
  }

  filtrar(): void {
    this.paginationOptionsIndividual = new PaginationOptions();
    this.paginationOptionsAgrupadas = new PaginationOptions();
    this.cargarRevisionesAgrupadas();
    this.cargarRevisionesIndividuales()
  }

  limpiarFiltro(resetPagination = false) {
    this.filtros = new RevisionFilterSupervisor();
    if(resetPagination){
      this.paginationOptionsIndividual = new PaginationOptions();
      this.paginationOptionsAgrupadas = new PaginationOptions();
    }
    this.filtrar();
  }
}
