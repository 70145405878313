import { Injectable } from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import { Observable } from "rxjs";
import { Constants } from "../../../../base/constants/constants";
import {PaginationOptions} from "../../../models/paginationOptions";
import {estadosRevisionCorreccion} from "../constants/EstadosRevisionCorreccion";
import {ProblemaCorreccionDTO} from "../dto/problemaCorreccionDTO";
import {RevisionFilter} from "../qa-grabaciones/filters/revision-filter";
import { RevisionFilterSupervisor } from "../qa-grabaciones-supervisor/filters/revision-filter-supervisor";

@Injectable({
  providedIn: 'root'
})
export class QaGrabacionesSupervisorService {

  estadosFiltro = estadosRevisionCorreccion;


  constructor(
    private http: HttpClient
  ) { }
  getEstadoById(id: number) {
    for (let estado of Object.values(this.estadosFiltro)) {
      if (estado.id == id) {
        return estado;
      }
    }
    return this.estadosFiltro.DESCONOCIDO;
  }

  getListaRevisionesAgrupadasPorRut(options: PaginationOptions, filtros?: RevisionFilterSupervisor): Observable<any> {
    let params = new HttpParams();
    params = params.set('pageNum', options.page);
    params = params.set('pageSize', options.size);

    if (filtros) {
      
      if (filtros.rutDocente) {
        params = params.set('rutDocente', filtros.rutDocente.toString());
      }
      if (filtros.estadoRevision && filtros.estadoRevision !== -1) {
        params = params.set('estadoRevision', filtros.estadoRevision.toString());
      }
      if (filtros.idRevisor) {
        params = params.set('revisor', filtros.idRevisor);
      }
      if (filtros.cantidadVideos && filtros.simboloCantidad) {
        params = params.set('simboloCantidad', filtros.simboloCantidad);
        params = params.set('cantidadVideos', filtros.cantidadVideos.toString());
        
      }
    }

    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/supervisor/revisiones-agrupadas`;
    return this.http.get(url, { params: params });
  }
  getListaRevisionesIndividuales(options: PaginationOptions, filtros?: RevisionFilterSupervisor): Observable<any> {
    let params = new HttpParams();
    params = params.set('pageNum', options.page);
    params = params.set('pageSize', options.size);

    if (filtros) {
      
      if (filtros.rutDocente) {
        params = params.set('rutDocente', filtros.rutDocente.toString());
      }
      if (filtros.estadoRevision != null && filtros.estadoRevision !== -1) {
        params = params.set('estadoRevision', filtros.estadoRevision.toString());
      }
      
      if (filtros.idRevisor) {
        params = params.set('revisor', filtros.idRevisor);
      }
      if (filtros.cantidadVideos && filtros.simboloCantidad) {
        params = params.set('simboloCantidad', filtros.simboloCantidad);
        params = params.set('cantidadVideos', filtros.cantidadVideos.toString());
        
      }
    }

    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/supervisor/revisiones-individuales`;
    return this.http.get(url, { params: params });
  }

  getDatosRevisiones(): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/supervisor/datos-revisiones`;
    return this.http.get(url);
  }

  getRevisoresPorRutDocente(rutDocente: number): Observable<string[]> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/supervisor/revisores`;
    return this.http.get<string[]>(url, {
      params: { rutDocente: rutDocente },
    });
  }

  getRubricaPorRutDocenteYRevisor(rutDocente: number , idRevisor : string): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/supervisor/rubrica`;
    return this.http.get<any>(url, {
      params: { rutDocente: rutDocente, idRevisor: idRevisor },
    });
  }

  actualizarProblemaSupervisor(idProblema: number, requestBody: any): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/supervisor/problema/${idProblema}`;
    return this.http.put<any>(url, requestBody);
  }
  
}
