import { Component, ElementRef, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DirectorFilter } from "../../models/directorFilter";
import { DirectorService } from "../../services/director.service";
import { PaginationOptions } from "../../models/paginationOptions";
import { ErrorMensajeService } from "src/app/base/services/errorMensaje.service";
import { Constants } from "src/app/base/constants/constants";
import { Directores } from "../../models/director";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/base/services/auth.service";
import Swal from "sweetalert2";
import { DependenciaService } from "../../services/dependencia.service";
import { RegionService } from "../../services/region.service";
import { EstablecimientoEncargado } from "../../models/establecimientoEncargado";

@Component({
  selector: 'app-directores',
  templateUrl: './directores.component.html',
  styleUrls: ['./directores.component.css']
})
export class DirectoresListComponent {
  filtros: DirectorFilter = new DirectorFilter();
  pageIndex: number;
  length: number;
  isLoading = false;
  faSpinner = faSpinner;
  options: PaginationOptions = new PaginationOptions();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  formularioEdicionOriginal!: Directores;
  response: any;
  descargandoCsv: any;
  directorForm!: FormGroup;
  estaEditando: boolean = false;
  estaAgregando: boolean = false;
  roles: string[] = ['ROLE_ADMIN','ADM_REGISTRO'];
  nombreEstablecimiento: string = "";
  directorEstablecimiento: string = "";
  @ViewChild("abremodal") abremodal: ElementRef;
  seguimientoEdicion: number = 1;
  regiones: Region[] = [];
  dependencias: Dependencia[] = [];
  establecimientosEncargado!: any[];
  encargadoSeleccionado!: any;
  queryRBD:number = null;

  establecimientoDetalles : { key: string, value: string }[] = [
    { key: 'nombre', value:"Nombre"},
    { key: 'region', value:"Región"},
    { key: 'comuna', value:"Comuna"},
  ]

  encargadoDetalles : { key: string, value: string }[] = [
    { key: 'rut', value: 'Rut' },
    { key: 'nombres', value: 'Nombres' },
    { key: 'primerApellido', value: 'Primer Apellido' },
    { key: 'segundoApellido', value: 'Segundo Apellido' },
    { key: 'correo', value: 'Correo' },
    { key: 'telefono', value: 'Teléfono' },
    { key: 'rutSostenedor', value: 'Rut Empresa Sost.' },
    { key: 'nombreEstablecimiento', value: 'Nombre Empresa Sost.' },
  ];

  constructor(
    private directorService: DirectorService,
    private dependenciaService: DependenciaService,
    private regionService: RegionService,
    private authService: AuthService,
    private errorMensaje: ErrorMensajeService
  ) {
    this.directorForm = new FormGroup({
          rut: new FormControl('', [
            Validators.required,
            Validators.pattern(/^\d+$/)
          ]),
          dv: new FormControl(''),
          rbd: new FormControl('', [
            Validators.required,
            Validators.pattern(/^\d+$/)
          ]),
          nombres: new FormControl('', Validators.required),
          primerApellido: new FormControl('', Validators.required),
          segundoApellido: new FormControl('', Validators.required),
          correo: new FormControl('', Validators.required),
          telefono: new FormControl('', Validators.required)
        });
        this.directorForm.updateValueAndValidity();
  }



  ngOnInit(): void {
    this.filtrar()
    this.getDependencias();
    this.getRegiones();
    this.directorForm.get('rbd')?.valueChanges.subscribe((value: string) => {
      this.directorService.getNombreEstablecimiento(value).subscribe({
        next: (response: any)=>{
          this.nombreEstablecimiento = response;
        },
        error: (error:any)=>{
          this.nombreEstablecimiento = "";
        }

      })
      this.directorService.getDirectorEstablecimiento(value).subscribe({
        next: (response: any)=>{
          this.directorEstablecimiento = response;
        },
        error: (error:any)=>{
          this.directorEstablecimiento = "";
        }

      })
    });


  }

  filtrar() {
    this.options.page = 1;
    this.options.size = 10;
    this.getDirectores();
  }

  getDirectores(){
    this.isLoading = true;
    this.directorService.directoresList(this.options,this.filtros.rutDirector, this.filtros.rbd,
    this.filtros.dependencia, this.filtros.region, this.filtros.rutSostenedor, this.filtros.rutEncargado).subscribe({
     next: (response: any)=>{
       this.response = response.list;
       this.pageIndex = (response.pageNum - 1);
       this.length = response.total;
       this.isLoading = false;
     },
     error: (err: any)=>{
      this.isLoading = false;
      this.errorMensaje.show(err, "obtener los Directores")
     }

   })

  }



  limpiar() {
    this.filtros = new DirectorFilter();
    this.filtrar();
  }
  descargarCsv() {
    const url = `${Constants.URL_SERVICE.BASE_DIRECTOR + Constants.URL_SERVICE.DIRECTOR.DESCARGAR}`;
    const filename = "listaDirectores.csv"
    this.descargandoCsv = true;
    this.directorService.getFile(url, this.filtros.rutDirector, this.filtros.rbd, this.filtros.dependencia, this.filtros.region,
      this.filtros.rutSostenedor, this.filtros.rutEncargado
    ).subscribe((response: any) => {
      this.directorService.downloadBlob(filename, response);
      this.descargandoCsv = false;
    })
  }
  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.getDirectores();
  }
  agregando() {
    this.estaAgregando = true;
    this.estaEditando = false;
    this.directorForm.reset();
  }

  editando(rut: number, rbd:number, nombres: string, primerApellido:string, segundoApellido:string, correo:string, telefono:number) {
    this.estaAgregando = false;
    this.estaEditando = true;
    this.directorForm.setValue({
      rut: rut,
      dv: 0,
      rbd:rbd,
      nombres: nombres,
      primerApellido:primerApellido,
      segundoApellido:segundoApellido,
      correo:correo,
      telefono:telefono

    })

    this.abremodal.nativeElement.click();
  }

  agregarDirector(){
    this.isLoading = true;
    let rut_invertido = this.directorForm.get('rut').value.toString();
    const secuencia = [2, 3, 4, 5, 6, 7, 2, 3];
    let suma: number = 0;
    for (let i = 0; i < rut_invertido.length; i++){
      suma = suma + (secuencia[i] * rut_invertido[rut_invertido.length - i - 1] )
    }
    const resto = suma % 11;
    const dv = 11 - resto;
    if (dv === 11) {
      this.directorForm.controls['dv'].setValue('0')
    } else if (dv === 10) {
      this.directorForm.controls['dv'].setValue('k')
    } else {
      this.directorForm.controls['dv'].setValue(dv.toString())
    }
    this.directorService.agregarDirector(this.directorForm.value).subscribe({
      next: (response: any)=>{
        this.isLoading = false;
        this.filtrar();
        Swal.fire("Director Agregado",response.status,"success");

      },
      error: (err: any)=>{
        this.isLoading = false;
        this.errorMensaje.show(err, "agregar director");

      }
    })

  }

  editarDirector(){
    this.isLoading = true;
    this.directorService.editarDirector(this.directorForm.value).subscribe({
      next: (response: any)=>{
        this.isLoading = false;
        this.filtrar();
        Swal.fire("Director Editado",response.status,"success");

      },
      error: (err: any)=>{
        this.isLoading = false;
        this.errorMensaje.show(err, "editar director");

      }
    })

  }

  eliminarDirector(rut:number, rbd:number){
    this.isLoading = true;
    this.directorService.eliminarDirector(rut, rbd).subscribe({
      next: (response: any)=>{
        this.isLoading = false;
        this.filtrar();
        Swal.fire("Director Eliminado",response.status,"success");

      },
      error: (err: any)=>{
        this.isLoading = false;
        this.errorMensaje.show(err, "eliminar director");

      }
    })


  }

  containRol() {
    return this.authService.containRol(this.roles);
  }

  getRegiones(): void {
    this.regionService.regionList().subscribe(response => {
      this.regiones = response;
    });
  }

  getDependencias(): void {
    this.dependenciaService.dependenciaList().subscribe(response => {
      this.dependencias = response;
    });
  }

  setEncargadoSeleccionado(encargado:  any) {
    this.encargadoSeleccionado = encargado;
    console.log(encargado);
    this.directorService.datosEncargados(encargado.rut).subscribe({
      next: (response: any)=>{
        this.establecimientosEncargado = response;
      }
    })
  }



}

export interface Dependencia {
  id: number;
  nombre: string;
  sector: Sector;
}

export interface Sector {
  id: number;
  nombre: string;
}

export interface Region {
  idRegion: number;
  nombre: string;
  simbolo: number;
  orden: number;
}
