<mat-card class="p-4 flex flex-col min-h-screen" style="box-shadow: none !important;">
  <!-- Título y Ayuda -->
  <div class="flex justify-between items-center mb-4">
    <p class="text-blue-700 text-2xl font-bold border-4 p-2 border-b-red-600 border-r-red-600 border-t-blue-600 border-l-blue-600 rounded-tl-2xl rounded-br-2xl">
      Supervisión de Revisiones de Grabaciones
    </p>
    <button mat-stroked-button color="primary" class="flex items-center gap-2">
      <mat-icon>help</mat-icon>
      Ayuda
    </button>
  </div>

  <!-- Resumen de Revisiones -->
  <div class="grid grid-cols-2 md:grid-cols-3 gap-4 mb-4">
    <div class="bg-blue-100 p-3 rounded-lg outline outline-2 text-blue-800 shadow-md text-center cursor-pointer hover:shadow-lg">
      <mat-icon style="font-size: 36px; height: 36px; width: 36px;" class="text-blue-600">assessment</mat-icon>
      <p class="text-lg font-semibold text-gray-800 mt-2">Total</p>
      <p class="text-2xl font-bold text-blue-700">{{ revisiones.totalRevisiones }}</p>
    </div>
    <div class="bg-green-100 p-3 rounded-lg outline outline-2 text-green-700 shadow-md text-center cursor-pointer hover:shadow-lg">
      <mat-icon style="font-size: 36px; height: 36px; width: 36px;" class="text-green-600">check_circle</mat-icon>
      <p class="text-lg font-semibold text-gray-800 mt-2">Procesadas</p>
      <p class="text-2xl font-bold text-green-700">{{ revisiones.totalProcesadas }}</p>
    </div>
    <div class="bg-orange-100 p-3 rounded-lg outline outline-2 text-orange-700 shadow-md text-center cursor-pointer hover:shadow-lg">
      <mat-icon style="font-size: 36px; height: 36px; width: 36px;" class="text-orange-600">hourglass_empty</mat-icon>
      <p class="text-lg font-semibold text-gray-800 mt-2">Pendientes</p>
      <p class="text-2xl font-bold text-orange-700">{{ revisiones.totalPendientes }}</p>
    </div>
  </div>


  <!-- Filtros Compactos -->
  <div class="p-3 bg-white border border-gray-300 rounded-lg shadow-sm relative mb-6">
    <!-- Encabezado del Card -->
    <div class="absolute top-0 left-0 h-full w-1 bg-blue-500 rounded-tl-lg rounded-bl-lg"></div>
    <div class="flex items-center gap-2 mb-3 pl-4">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 010 2H4a1 1 0 01-1-1zm2 5a1 1 0 011-1h8a1 1 0 010 2H6a1 1 0 01-1-1zm3 5a1 1 0 000-2h4a1 1 0 100 2H8z" clip-rule="evenodd" />
      </svg>
      <h2 class="text-lg font-semibold text-blue-700">Filtros</h2>
    </div>

    <!-- Contenido de los filtros -->
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
      <!-- Rut Docente -->
      <div class="relative">
        <label for="rut-docente" class="block text-gray-700 font-medium mb-0.5">Rut Docente</label>
        <input id="rut-docente" type="text" placeholder="Ingrese Rut"
               [(ngModel)]="filtros.rutDocente"
               class="w-full px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400 text-sm" />
      </div>

      <!-- Estado Revisión -->
      <div class="relative">
        <label for="estado-revision" class="block text-gray-700 font-medium mb-0.5">Estado Revisión</label>
        <select id="estado-revision"
                [(ngModel)]="filtros.estadoRevision"
                class="w-full px-2 py-1 border border-gray-300 rounded bg-white focus:outline-none focus:ring-2 focus:ring-blue-400 text-sm">
          <option [value]="-1">Todos los estados</option>
          <option *ngFor="let estado of estadosFiltroFiltrados" [value]="estado.id">
            {{estado.display_text}}
          </option>
        </select>
      </div>

      <!-- Revisor -->
      <div class="relative">
        <label for="revisor" class="block text-gray-700 font-medium mb-0.5">Revisor</label>
        <input id="revisor" type="text" placeholder="Ingrese Revisor"
               [(ngModel)]="filtros.idRevisor"
               class="w-full px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400 text-sm" />
      </div>

     <!-- Cantidad de Videos -->
    <div class="relative">
      <label class="block text-gray-700 font-medium mb-0.5">Cantidad de Videos</label>
      <div class="flex items-center gap-1 border border-gray-300 rounded px-2 py-1 bg-white focus-within:ring-2 focus-within:ring-blue-400">
        <!-- Selector de Operador -->
        <select [(ngModel)]="filtros.simboloCantidad"
                class="px-2 py-1 bg-transparent border-none focus:ring-0 focus:outline-none text-gray-700 text-sm w-1/2">
          <option value="" disabled selected>Seleccione</option>
          <option value=">">></option>
          <option value="<"><</option>
          <option value="=">=</option>
        </select>
        <!-- Input de Cantidad -->
        <input type="number" [(ngModel)]="filtros.cantidadVideos" placeholder="Cantidad"
              class="w-1/2 px-2 py-1 border-none focus:ring-0 focus:outline-none text-gray-700 text-sm" />
      </div>
</div>

    </div>

<div class="flex justify-end mt-4 gap-2">
  <button mat-raised-button color="primary" class="px-3 py-1 text-xs flex items-center gap-1" (click)="filtrar()">
    <mat-icon class="text-white mr-2">search</mat-icon> Filtrar
  </button>

  <button mat-stroked-button color="warn" class="px-3 py-1 text-xs flex items-center gap-1" (click)="limpiarFiltro()">
    <mat-icon class="text-red-600 mr-2">clear</mat-icon> Limpiar
  </button>
</div>

  </div>

  <app-supervisor-table 
    [isLoadingIndividual]="isLoadingIndividual" 
    [isLoadingGrouped]="isLoadingGrouped" 
    [individualRevisions]="individualRevisions"  
    [groupedRevisions]="groupedRevisions"
    (pageChange)="onPageChange($event)">
  </app-supervisor-table>
</mat-card>
