import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { PaginationOptions } from 'src/app/registros/models/paginationOptions';
import { RecepcionFiltro } from '../models/RecepcionFiltro';
import { RecepcionBultoService } from '../services/recepcion-bulto.service';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import Swal from 'sweetalert2';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe, formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Constants } from 'src/app/base/constants/constants';
import { Toast } from 'src/app/base/services/toast';
import { AuthService } from 'src/app/base/services/auth.service';
import { ModulosAutorizacion } from 'src/app/base/models/ModulosAutorizacion';
import { AutorizacionesService } from 'src/app/base/services/autorizaciones.service';

@Component({
  selector: 'app-recepcion-bulto',
  templateUrl: './recepcion-bulto.component.html',
  styleUrls: ['./recepcion-bulto.component.css'],
  providers: [DatePipe],
})
export class RecepcionBultoComponent implements OnInit{
  options: PaginationOptions = new PaginationOptions();
  pageIndex: number;
  length: number;
  isLoading = false;
  response: any;
  folio!: string;
  seguimiento!: string ;
  estado!: number;
  observacion!: string;
  folioEdicion!: string;
  seguimientoEdicion!: string ;
  estadoEdicion!: number;
  observacionEdicion!: string;
  filtros: RecepcionFiltro = new RecepcionFiltro();
  eliminarSeguimiento: string ;
  eliminarFolio: string ;
  today = new Date();
  minDate = new Date("Saturday, 1 March 2024 0:00:00");
  fechaRecepcion = new Date();
  fechaFinalRecepcion!: string;
  fechaRecepcionEdicion = new Date();
  fechaFinalRecepcionEdicion!: string;
  recepcionForm!: FormGroup;
  estaDescargandoExcel!: boolean;
  faSpinner = faSpinner;
  numeroSeguimientoEdicion!: string;
  checkNumeroSeguimiento: boolean = null;
  numeroSeguimientoActual!: string;
  roles: string[] = ['ROLE_ADMIN','ADM_REGISTRO','ADM_GRABACIONES'];
  frase!: string;
  idEdicion!: number;
  seguimientoEdicionOriginal: string;
  folioEdicionOriginal: string;
  tieneCajaAsociada!: boolean;
  @ViewChild("abremodal") abremodal: ElementRef;

  authModulos: ModulosAutorizacion = new ModulosAutorizacion();
  permisosCRUD!: boolean;
  permisosDescarga!: boolean;

  constructor(
    private recepcionBultoService: RecepcionBultoService,
    private errorMensaje: ErrorMensajeService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private permisoPara: AutorizacionesService
  ){
    const autorizaciones = this.authModulos.moduloTracklog[2].authorizationRoles;
    this.permisosDescarga = this.permisoPara.containRol(autorizaciones['DOWNLOAD']);
    this.permisosCRUD = this.permisoPara.containRol(autorizaciones['CREATE']);
  }

  ngOnInit(): void {
    this.filtros.folio = "";
    this.filtros.seguimiento = "";
    this.filtros.estado = 0;
    this.filtrar();
  }

  limpiar(){
    this.filtros = new RecepcionFiltro();
    this.filtrar();
  }

  filtrar(){
    this.options.page = 1;
    this.options.size = 10;
    this.obtenerDatos();
  }

  obtenerDatos(){
    this.recepcionBultoService.bultosList(this.options,this.filtros).subscribe({
      next: (response: any) => {
        this.response = response.list;
        this.pageIndex = (response.pageNum - 1);
        this.length = response.total;
      },
      error: (err)=>{
        this.errorMensaje.show(err, "listar bultos");

      }
    })
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.obtenerDatos();
  }

  agregarBulto(){
    this.fechaFinalRecepcion = this.fechaRecepcion ?
      this.datePipe.transform(this.fechaRecepcion, 'yyyy-MM-dd HH:mm:ss Z').toString() : null
    this.recepcionBultoService.checkNumeroSeguimiento(this.seguimiento)
    .subscribe({
      next: (check: any) => {
        if (check == 1) {
          this.recepcionBultoService.checkNumeroSeguimientoBulto(this.seguimiento)
          .subscribe({
            next: (secondCheck: any) => {
              if(secondCheck == 0){
                this.recepcionBultoService.checkFolio(this.folio)
                .subscribe({
                  next: (folioCheck: any) => {
                    if(folioCheck == 0){
                      this.recepcionBultoService.agregarBulto(this.folio,
                        this.seguimiento,
                        this.fechaFinalRecepcion,
                        this.estado,
                        this.observacion).subscribe({
                          next:(response: any) => {
                            this.filtrar();
                            this.resetearVariables();
                            Swal.fire("Bulto Creado",response.status,"success");

                          },
                          error: (err: any) => {
                            this.resetearVariables();
                            this.errorMensaje.show(err, "agregar bulto");
                          }

                        })

                    }
                    else{
                      Swal.fire({
                        text: "El Folio ya esta asociado a un bulto",
                        icon: 'warning',
                        confirmButtonText: 'Entendido.'

                      })
                    }
            }})


              }
              else{
                Swal.fire({
                  text: "El numero de seguimiento ya esta asociado a un bulto",
                  icon: 'warning',
                  confirmButtonText: 'Entendido.'

                })

              }
            }})

        }
        else{
          Swal.fire({
            text: "El numero de seguimiento es incorrecto",
            icon: 'warning',
            confirmButtonText: 'Entendido.'

          })
        }
      },
      error: (err) => {
        this.errorMensaje.show(err, "ingresas el número de seguimiento");
      },
    })

  }

  eliminar(seguimiento: string){
    this.recepcionBultoService.checkCaja(this.eliminarFolio).subscribe({
      next:(response: any) => {
        console.log(response)
        if(!response){
          this.recepcionBultoService.eliminarBulto(seguimiento).subscribe({
            next:(response: any) => {
              this.filtrar();
              this.eliminarSeguimiento = null;
              this.eliminarFolio = null;
              Swal.fire("Bulto Eliminado",response.status,"success");

            },
            error: (err: any) => {
              this.errorMensaje.show(err, "eliminar bulto");
              this.eliminarSeguimiento = null;
              this.eliminarFolio = null;
            }

          })
               }
        else{
          Swal.fire({
            text: "El bulto esta asociado a una caja",
            icon: 'warning',
            confirmButtonText: 'Entendido.'

          })

        }
      },
      error:(err:any) => {
        this.errorMensaje.show(err, "eliminar bulto");
        this.eliminarSeguimiento = null;
        this.eliminarFolio = null;

      }
    })
  }

  ventanaEliminar(seguimiento: string, folio: string){
    this.eliminarSeguimiento = seguimiento;
    this.eliminarFolio = folio;
    Swal.fire({
      title: '¿Desea eliminar el bulto '+this.eliminarFolio +'?',
      text: 'Este proceso no se puede deshacer',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#d9534f',
      cancelButtonColor: '#0275d8',

    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminar(this.eliminarSeguimiento);
      }
    })
  }

  ventanaAgregar(){
    this.frase= 'N° seguimiento: ' + this.seguimiento + (this.estado == 1 ?'\nEstado: Buen Estado': this.estado == 2 ?'\nEstado: Mal Estado': null);
    Swal.fire({
      title: '¿Desea crear el bulto '+this.folio +'?',
      text: this.frase,
      icon: 'question',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Agregar',
      confirmButtonColor: '#d9534f',
      cancelButtonColor: '#0275d8',

    }).then((result) => {
      if (result.isConfirmed) {
        this.agregarBulto();
      }
      if(result.isDenied){
        this.resetearVariables();
      }
      if(result.isDismissed){
        this.resetearVariables();
      }
    })
  }

  ventanaEditar(){
    this.frase= 'N° seguimiento: ' + this.seguimientoEdicion + (this.estadoEdicion == 1 ?'\nEstado: Buen Estado': this.estadoEdicion == 2 ?'\nEstado: Mal Estado': null);
    Swal.fire({
      title: '¿Desea editar el bulto '+this.folioEdicion +'?',
      text: this.frase,
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Editar',
      confirmButtonColor: '#d9534f',
      cancelButtonColor: '#0275d8',

    }).then((result) => {
      if (result.isConfirmed) {
        this.editarBulto();
      }
      if(result.isDenied){
        this.resetearVariables();
      }
      if(result.isDismissed){
        this.resetearVariablesEdicion();
      }
    })
  }

  editarBulto(){
          this.fechaFinalRecepcionEdicion = this.fechaRecepcionEdicion ?
          this.datePipe.transform(this.fechaRecepcionEdicion, 'yyyy-MM-dd HH:mm:ss Z').toString() : null
          this.recepcionBultoService.checkNumeroSeguimiento(this.seguimientoEdicion)
          .subscribe({
            next: (check: any) => {
              if (check == 1) {
                this.recepcionBultoService.checkNumeroSeguimientoBulto(this.seguimientoEdicion)
                .subscribe({
                  next: (secondCheck: any) => {
                    if(secondCheck == 0  || this.seguimientoEdicion == this.seguimientoEdicionOriginal){
                      this.recepcionBultoService.checkFolio(this.folioEdicion)
                      .subscribe({
                        next: (folioCheck: any) => {
                          if(folioCheck == 0 || this.folioEdicion == this.folioEdicionOriginal){
                            this.recepcionBultoService.editarBulto(this.idEdicion,
                              this.folioEdicion,
                              this.seguimientoEdicion,
                              this.fechaFinalRecepcionEdicion,
                              this.estadoEdicion,
                              this.observacionEdicion).subscribe({
                                next:(response: any) => {
                                  this.filtrar();
                                  this.resetearVariables();
                                  Swal.fire("Bulto Editado",response.status,"success");
    
                                },
                                error: (err: any) => {
                                  this.resetearVariables();
                                  this.errorMensaje.show(err, "editar bulto");
                                }
    
                              })
    
                          }
                          else{
                            Swal.fire({
                              text: "El Folio ya esta asociado a un bulto",
                              icon: 'warning',
                              confirmButtonText: 'Entendido.'
    
                            })
                          }
                  }})
    
    
                    }
                    else{
                      Swal.fire({
                        text: "El numero de seguimiento ya esta asociado a un bulto",
                        icon: 'warning',
                        confirmButtonText: 'Entendido.'
    
                      })
    
                    }
                  }})
    
              }
              else{
                Swal.fire({
                  text: "El numero de seguimiento es incorrecto",
                  icon: 'warning',
                  confirmButtonText: 'Entendido.'
    
                })
              }
            },
            error: (err) => {
              this.errorMensaje.show(err, "ingresas el número de seguimiento");
            },
          })

  }
  resetearVariables(){
    this.folio = null;
    this.seguimiento = null;
    this.fechaRecepcion = null;
    this.estado = null;
    this.observacion = null;

  }

  resetearVariablesEdicion(){
    this.idEdicion = null;
    this.folioEdicion = null;
    this.seguimientoEdicion = null;
    this.fechaRecepcionEdicion = null;
    this.estadoEdicion = null;
    this.observacionEdicion = null;

  }

  variablesEditar(id:number, folio: string, seguimiento: string, fechaRecepcion: Date,
    estado:number, observacion: string){
      this.idEdicion = id;
      this.folioEdicion = folio;
      this.folioEdicionOriginal = folio;
      this.seguimientoEdicion = seguimiento;
      this.seguimientoEdicionOriginal = seguimiento
      this.fechaRecepcionEdicion = fechaRecepcion;
      this.estadoEdicion = estado;
      this.observacionEdicion = observacion;
      this.recepcionBultoService.checkCaja(folio).subscribe({
        next:(response: any) => {
          this.tieneCajaAsociada = response;
          this.abremodal.nativeElement.click();
        }
      })

      console.log(this.tieneCajaAsociada);
    }

    onDateChange(event: MatDatepickerInputEvent<Date>) {
      const date = event.value;
      if (date) {
        const formattedDate = formatDate(date, 'dd-MM-yyyy', 'en-US');
        console.log('Fecha seleccionada:', formattedDate);

        const storageDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
        console.log('Fecha para almacenamiento:', storageDate);
      }
    }

    descargarBultos(){
      const url = `${Constants.URL_SERVICE.BASE_GRABACIONES + Constants.URL_SERVICE.RECEPCION_BULTO.DESCARGAR}`;
      const filename = "listaBultos.csv"
      this.estaDescargandoExcel = true;

      this.recepcionBultoService.descargarBultos(url, this.filtros)
      .subscribe(
        {
          next: (response: any) => {
            Toast.fire(
              "Descarga de fichas de seguimiento",
              "Descarga finalizada",
              "success"
            );
            this.recepcionBultoService.downloadBlob(filename, response);
            this.estaDescargandoExcel = false;
          }, error: (err: any) => {
            this.informarError(err, 'descargar las fichas de seguimiento');
            this.estaDescargandoExcel = false;
          }
        });
    }

    informarError(err: any, contexto: string) {
      this.errorMensaje.show(err, contexto).then((result:any) => {
        if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
          this.finalizarConsulta();
        }
      });
    }

    finalizarConsulta() {
      this.isLoading = false;
      setTimeout(() => {
      }, 2000);
    }

    checkearNumeroSeguimiento(seguimiento: string){
      this.recepcionBultoService.checkNumeroSeguimiento(seguimiento)
      .subscribe({
        next: (check: any) => {
          console.log(check);
          this.checkNumeroSeguimiento = check;

        },
        error: (err) => {
          this.informarError(err, 'validar el número de seguimiento');
          return false;
        },
      });
    }

    containRol() {
      return this.authService.containRol(this.roles);
    }
}
