<div class="overflow-auto border border-gray-300 rounded-lg shadow-sm bg-white">
  <table class="w-full table-fixed border-collapse text-sm">
    <thead class="bg-blue-700 text-blue-100">
      <tr>
        <th class="px-4 py-2 text-left font-semibold w-[30%]">Docente</th>
        <th class="px-4 py-2 text-left font-semibold w-[30%]">Revisiones</th>
        <th class="px-4 py-2 text-left font-semibold w-[40%]">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let revision of revisions"
        class="even:bg-gray-50 hover:bg-gray-100"
      >
        <!-- Columna Docente -->
        <td class="px-4 py-4">
          <div class="space-y-2">
            <div class="flex items-center gap-2">
              <mat-icon class="text-gray-600">badge</mat-icon>
              <span class="text-gray-700 font-medium">RUT:</span>
              <span class="text-gray-900 font-semibold">{{
                revision.rutDocente
              }}</span>
            </div>
            <div class="flex items-center gap-2">
              <mat-icon class="text-gray-600">group</mat-icon>
              <span class="text-gray-700 font-medium">Agrupación:</span>
              <span class="text-gray-900">
                {{ revision.agrupacionAsignatura.codigoAgrupacion }}:
                {{ revision.agrupacionAsignatura.nombreAgrupacion }}
              </span>
            </div>
            <div class="flex items-center gap-2">
              <mat-icon class="text-gray-600">menu_book</mat-icon>
              <span class="text-gray-700 font-medium">Asignatura:</span>
              <span class="text-gray-900">
                {{ revision.agrupacionAsignatura.codigoAsignatura }}:
                {{ revision.agrupacionAsignatura.nombreAsignatura }}
              </span>
            </div>
          </div>
        </td>

        <!-- Columna Revisiones -->
        <td class="px-4 py-4">
          <div class="space-y-2">
            <div class="flex items-center gap-2">
              <mat-icon class="text-gray-600">video_library</mat-icon>
              <span class="text-gray-700 font-medium">Cantidad de videos:</span>
              <span class="text-gray-900">{{ revision.totalVideos }}</span>
            </div>
            <div class="flex items-center gap-2">
              <mat-icon class="text-gray-600">more_time</mat-icon>
              <span class="text-gray-700 font-medium"
                >Duración total videos:</span
              >
              <span class="text-gray-900">{{
                revision.totalDuracionVideos | formatTime
              }}</span>
            </div>
          </div>
        </td>

        <!-- Columna Acciones -->
        <td class="px-4 py-2">
          <div class="flex flex-wrap gap-3">
            <ng-container *ngFor="let revisionData of revision.revisiones">
              <div
                class="bg-white border border-gray-300 rounded-lg shadow-sm flex flex-col justify-between w-full sm:w-80"
              >
                <!-- Información Principal -->
                <div class="p-3 flex flex-col text-sm gap-1">
                  <p
                    class="text-gray-700 font-semibold flex items-center gap-2"
                  >
                    <mat-icon class="text-gray-600 text-lg"
                      >assignment</mat-icon
                    >
                    Revisión #{{ revisionData.revision.id }}
                  </p>
                  <p class="text-gray-700 flex items-center gap-2">
                    <mat-icon class="text-gray-600 text-lg"
                      >assignment</mat-icon
                    >
                    Fecha video en nube:
                    {{
                      revisionData.revision.fechaVideoNube
                        ? (revisionData.revision.fechaVideoNube
                          | date : "yyyy-MM-dd HH:mm:ss")
                        : "No disponible"
                    }}
                  </p>
                  <div class="flex items-center font-medium gap-2">
                    <mat-icon class="text-gray-600 text-lg">info</mat-icon>
                    <span class="text-gray-700 font-medium">Estado:</span>
                    <span
                      [ngClass]="{
                        'bg-gray-50 text-gray-700':
                          service.getEstadoById(revisionData.revision.estadoId)
                            .id == 0 ||
                          service.getEstadoById(revisionData.revision.estadoId)
                            .id == 6,
                        'bg-blue-50 text-blue-700':
                          service.getEstadoById(revisionData.revision.estadoId)
                            .id == 1,
                        'bg-green-50 text-green-700':
                          service.getEstadoById(revisionData.revision.estadoId)
                            .id == 2 ||
                          service.getEstadoById(revisionData.revision.estadoId)
                            .id == 3,
                        'bg-red-50 text-red-700':
                          service.getEstadoById(revisionData.revision.estadoId)
                            .id == 4,
                        'bg-orange-50 text-orange-700':
                          service.getEstadoById(revisionData.revision.estadoId)
                            .id == 5
                      }"
                      class="rounded-md py-0.5 px-2.5 text-center outline outline-2 text-sm flex items-center scale-[0.75]"
                    >
                      <mat-icon
                        [ngStyle]="{
                          color: service.getEstadoById(
                            revisionData.revision.estadoId
                          ).display_color
                        }"
                        class="mr-2"
                      >
                        {{
                          service.getEstadoById(revisionData.revision.estadoId)
                            .icon
                        }}
                      </mat-icon>
                      <span>
                        {{
                          service.getEstadoById(revisionData.revision.estadoId)
                            .display_text
                        }}
                      </span>
                    </span>
                  </div>
                </div>

                <!-- Botón Detalles -->
                <div class="mt-auto">
                  <button
                    *ngIf="revisionData.revision.estado !== 0"
                    mat-raised-button
                    (click)="verDetalles(revisionData.revision.id)"
                    color="primary"
                    class="w-full text-sm flex items-center justify-center py-2 rounded-none border-t border-gray-200">
                    <mat-icon class="mr-2">visibility</mat-icon>
                    Detalles
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </td>
      </tr>
      <tr *ngIf="revisions.length === 0">
        <td colspan="3" class="px-4 py-2 text-center">
          No hay datos disponibles
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="flex justify-between items-center mt-4">
  <mat-paginator
    [length]="total"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="currentPage - 1"
    (page)="onPageChange($event)"
    showFirstLastButtons
    aria-label="Paginación"
  >
  </mat-paginator>
</div>
