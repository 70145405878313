<div class="overflow-auto border border-gray-300 rounded-lg shadow-sm bg-white">
  <table class="w-full border-collapse text-sm">
    <thead class="bg-blue-700 text-blue-100">
      <tr class="even:bg-gray-50 border-l-4 border-blue-700">
        <th class="px-4 py-2 text-left font-semibold">Revisión</th>
        <th class="px-4 py-2 text-left font-semibold">Docente</th>
        <th class="px-4 py-2 text-left font-semibold">Otros</th>
        <th class="px-4 py-2 text-left font-semibold">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let row of revisions">
        <tr
          class="even:bg-gray-50 hover:bg-gray-100 border-l-4 border-b-4 border-blue-700"
          [ngClass]="{
            'border-gray-700':
              service.getEstadoById(row.estado).id == 0 ||
              service.getEstadoById(row.estado).id == 6,
            'border-blue-700': service.getEstadoById(row.estado).id == 1,
            'border-green-500':
              service.getEstadoById(row.estado).id == 2 ||
              service.getEstadoById(row.estado).id == 3,
            'border-red-500': service.getEstadoById(row.estado).id == 4,
            'border-orange-300': service.getEstadoById(row.estado).id == 5
          }"
        >
          <!-- Información de la Revisión -->
          <td class="px-4 py-4">
            <div class="space-y-1">
              <div class="flex items-center gap-2">
                <mat-icon class="text-gray-600">tag</mat-icon>
                <span class="text-gray-700 font-medium">ID:</span>
                <span class="text-gray-900">{{ row.id }}</span>
              </div>
              <div class="flex items-center gap-2">
                <mat-icon class="text-gray-600 text-lg">info</mat-icon>
                <span class="text-gray-700 font-medium">Estado:</span>
                <span
                  [ngClass]="{
                    'bg-gray-50 text-gray-700':
                      service.getEstadoById(row.estado).id == 0 ||
                      service.getEstadoById(row.estado).id == 6,
                    'bg-blue-50 text-blue-700':
                      service.getEstadoById(row.estado).id == 1,
                    'bg-green-50 text-green-700':
                      service.getEstadoById(row.estado).id == 2 ||
                      service.getEstadoById(row.estado).id == 3,
                    'bg-red-50 text-red-700':
                      service.getEstadoById(row.estado).id == 4,
                    'bg-orange-50 text-orange-700':
                      service.getEstadoById(row.estado).id == 5
                  }"
                  class="rounded-md py-0.5 px-2.5 text-center outline outline-2 text-xs flex items-center scale-[0.9]"
                >
                  <mat-icon
                    [ngStyle]="{
                      color: service.getEstadoById(row.estado).display_color
                    }"
                    class="mr-2"
                  >
                    {{ service.getEstadoById(row.estado).icon }}
                  </mat-icon>
                  {{ service.getEstadoById(row.estado).display_text }}
                </span>
              </div>
              <div class="flex items-center gap-2">
                <mat-icon class="text-gray-600">calendar_today</mat-icon>
                <span class="text-gray-700 font-medium">Fecha creación:</span>
                <span class="text-gray-900">{{
                  row.fechaCreacion ?? "No disponible"
                }}</span>
              </div>
              <div class="flex items-center gap-2">
                <mat-icon class="text-gray-600">schedule</mat-icon>
                <span class="text-gray-700 font-medium">Duración video:</span>
                <span class="text-gray-900">{{
                  row.duracionVideo
                    ? (row.duracionVideo | formatTime)
                    : "No disponible"
                }}</span>
              </div>
            </div>
          </td>

          <!-- Información del Docente -->
          <td class="px-4 py-4">
            <div class="space-y-1">
              <div class="flex items-center gap-2">
                <mat-icon class="text-gray-600">badge</mat-icon>
                <span class="text-gray-700 font-medium">RUT:</span>
                <span class="text-gray-900 font-semibold">{{
                  row.rutDocente
                }}</span>
              </div>
              <div class="flex items-center gap-2">
                <mat-icon class="text-gray-600">group</mat-icon>
                <span class="text-gray-700 font-medium">Agrupación:</span>
                <span class="text-gray-900">{{
                  row.agrupacionAsignatura.codigoAgrupacion +
                    ": " +
                    row.agrupacionAsignatura.nombreAgrupacion
                }}</span>
              </div>
              <div class="flex items-center gap-2">
                <mat-icon class="text-gray-600">menu_book</mat-icon>
                <span class="text-gray-700 font-medium">Asignatura:</span>
                <span class="text-gray-900">{{
                  row.agrupacionAsignatura.codigoAsignatura +
                    ": " +
                    row.agrupacionAsignatura.nombreAsignatura
                }}</span>
              </div>
            </div>
          </td>

          <!-- Otra información -->
          <td class="px-4 py-4">
            <div class="space-y-1">
              <div class="flex items-center gap-2">
                <mat-icon class="text-gray-600">cloud_upload</mat-icon>
                <span class="text-gray-700 font-medium"
                  >Fecha video en nube:</span
                >
                <span class="text-gray-900">{{
                  row.otros?.fechaVideoEnNube ?? "No disponible"
                }}</span>
              </div>
              <div class="flex items-center gap-2">
                <mat-icon class="text-gray-600">video_library</mat-icon>
                <span class="text-gray-700 font-medium"
                  >Cantidad de videos del docente:</span
                >
                <span class="text-gray-900">{{
                  row.otros?.cantidadVideos ?? "No disponible"
                }}</span>
              </div>
              <div class="flex items-center gap-2">
                <mat-icon class="text-gray-600">more_time</mat-icon>
                <span class="text-gray-700 font-medium"
                  >Duración total videos:</span
                >
                <span class="text-gray-900">{{
                  row.otros?.duracionTotalVideos
                    ? (row.otros.duracionTotalVideos | formatTime)
                    : "No disponible"
                }}</span>
              </div>
            </div>
          </td>

          <!-- Acciones -->
          <td class="px-4 py-4">
              <button
                *ngIf="row.estado !== 0"
                mat-raised-button
                (click)="verDetalles(row.id)"
                color="primary"
                class="w-full text-sm flex items-center justify-center py-2 rounded-none border-t border-gray-200">
                <mat-icon class="mr-2">visibility</mat-icon>
                Detalles
              </button>

          </td>
        </tr>
      </ng-container>
      <!-- Mensaje si no hay datos -->
      <tr *ngIf="revisions.length === 0">
        <td colspan="4" class="px-4 py-4 text-center">
          No hay revisiones disponibles
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="flex justify-between items-center mt-4">
  <mat-paginator
    [length]="total"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="currentPage - 1"
    (page)="onPageChange($event)"
    showFirstLastButtons
    aria-label="Paginación"
  >
  </mat-paginator>
</div>
