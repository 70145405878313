import { Component, ElementRef, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Constants } from 'src/app/base/constants/constants';
import { PaginationOptions } from 'src/app/base/models/paginationOptions';
import { AutorizacionesService } from 'src/app/base/services/autorizaciones.service';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { Toast } from '../../../base/services/toast';
import { Oficio } from '../../models/oficio';
import { OficiosFilter } from '../../models/oficiosFilter';
import { OficiosService } from '../../services/oficios.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-oficios',
  templateUrl: './oficios.component.html',
  styleUrls: ['./oficios.component.css']
})
export class OficiosComponent {
  faExcel = faFileExcel;
  faSpinner = faSpinner;
  estaDescargandoExcel: boolean = false;
  estados: {id: number, nombre: string}[];
  initLoading: boolean = true;
  activeColumn: string = "fechaIngreso";
  isDesc!: boolean;
  orderFilter: string;

  isDragging = false;
  startX = 0;
  scrollLeft = 0;

  ordenColumnas:{[key:string]: {isDesc: boolean, type: string} }= {
    "fechaIngreso": {isDesc: true, type: "text"},
    "rutDocente": {isDesc: true, type: "number"},
    "rutEncargado": {isDesc: true, type: "number"}
  };

  oficioDataSection: { key: string, value: string }[] = [
    { key: 'fechaIngreso', value: 'Fecha de Ingreso' },
    { key: 'nombreDocente', value: 'Nombre del Docente'},
    { key: 'rutDocente', value: 'Rut Docente' },
    { key: 'rutEncargado', value: 'Rut Encargado' },
    { key: 'rutSostenedor', value: 'Rut Empresa' },
    { key: 'rbd', value: 'RBD' },
    { key: 'archivo', value: 'Archivo' },
    { key: 'tipo', value: 'Tipo' },
    { key: 'estado', value: 'Estado' },
    { key: 'observacion', value: 'Observación' },
    { key: 'causal', value: 'Causal' },
    { key: 'estadoSuspension', value: 'Estado Suspensión'},
    { key: 'motivoSuspension', value: 'Motivo Suspensión'},
    { key: 'nombreBeneficio', value: 'Beneficios'},
    { key: 'debeRendir', value: 'Estado Convocatoria'},
    { key: 'estadoDocente', value: 'Estado Registro'},
    { key: 'motivoNoInscripcion', value: 'Motivo no Inscripción'}
  ]

  oficioDataTable: { key: string, value: string }[] = [
    { key: 'fechaIngreso', value: 'Fecha de Ingreso' },
    { key: 'rutDocente', value: 'Rut Docente' },
    { key: 'rutEncargado', value: 'Rut Encargado' },
    { key: 'rbd', value: 'RBD' },
    { key: 'estado', value: 'Estado' },
    { key: 'observacion', value: 'Observación' },
    { key: 'causal', value: 'Causal' },
    { key: 'archivo', value: 'Archivo' },
    { key: 'tipo', value: 'Tipo' },
    { key: 'dependencia', value: 'Dependencia' },
    { key: 'estadoSuspension', value: 'Estado Suspensión' },
    { key: 'motivoSuspension', value: 'Motivo Suspensión' },
    { key: 'debeRendir', value: 'Debe Rendir' },
    { key: 'estadoDocente', value: 'Estado Registro'},
    { key: 'motivoNoInscripcion', value: 'Motivo no Inscripción'},
    { key: 'beneficio', value: 'Beneficio' },
  ]

  toggleColumns: { key: string, value: boolean, name: string }[] = [
    { key: 'dependencia', value: true, name: 'Dependencia' },
    { key: 'estadoSuspension', value: true, name: 'Estado Suspensión' },
    { key: 'motivoSuspension', value: true, name: 'Motivo Suspensión' },
    { key: 'debeRendir', value: true, name: 'Debe Rendir' },
    { key: 'estadoDocente', value: true, name: 'Estado Registro'},
    { key: 'motivoNoInscripcion', value: true, name: 'Motivo no Inscripción'},
    { key: 'beneficio', value: true, name: 'Beneficio' },
  ];

  @ViewChild('abrirModalPDF') abrirModalPDF: any;

  keyValue: { key: string, value: string }[] = this.oficioDataSection

  keyValueTable: { key: string, value: string }[] = this.oficioDataTable

  displayedColumnsModal: { [key: string]: string } = this.keyValue.reduce((acc: { [key: string]: string }, curr) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {});

  displayedColumnsTable: { [key: string]: string } = this.keyValueTable.reduce((acc: { [key: string]: string }, curr) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {});

  displayedColumns: string[] = Object.keys(this.displayedColumnsModal);
  nameDisplayedColumns: string[] = Object.values(this.displayedColumnsTable);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('iframe', {static:false}) iframe!: ElementRef;
  isLoading = false;
  reloadPaginator = true;
  mensajeError: boolean = false;
  dataSource: MatTableDataSource<Oficio> = new MatTableDataSource();
  pageIndex: number;
  pageSize: number;
  length: number;
  filtros: OficiosFilter = new OficiosFilter();
  oficios: Oficio[];
  options: PaginationOptions = new PaginationOptions();
  oficioSeleccionadoModal: any;

  permisoAcciones: boolean = false;
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  estadoOficio!: number;
  observacionOficio!: string;
  rows: number = 2;

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  constructor(
    private OficiosService: OficiosService,
    private errorMensaje: ErrorMensajeService,
    private permisoPara: AutorizacionesService,
    private http: HttpClient,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit() {
    if(!this.paginator) return;
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;

  }

  ngOnInit(): void {
    this.filtros = new OficiosFilter();
    this.permisoAcciones = this.permisoPara.permisoModificaciones();
    this.getOficios();
    this.getFiltrosFormulario();
  }

  getOficios() {
    this.isLoading = true;
    this.reloadPaginator = true;
    this.oficios =[];
    this.OficiosService.oficiosList(this.options, this.filtros.rutDocente,
      this.filtros.rutEncargado,
      this.filtros.rutSostenedor,
      this.filtros.estadoRegistro,).subscribe(
      { next : (response:any) => {
        this.initLoading = false;
        if (!response.listaOficios){
          this.dataSource = new MatTableDataSource<Oficio>(null);
          this.oficios = null;
          this.isLoading = false;
          return;
        }
        this.dataSource = new MatTableDataSource<Oficio>(response.listaOficios);
        this.length = response.total;
        this.oficios = response.listaOficios;
        this.pageIndex = (response.pageNum - 1);
        this.isLoading = false;
      }, error: (error: any) => {
        this.initLoading = false;
        this.errorMensaje.show(error, "obtener los oficios");
        this.dataSource = new MatTableDataSource<Oficio>(null);
        this.oficios = null;
        this.isLoading = false;
      }});
  }

  sort(property: string) {
    this.activeColumn = property;
    const isDesc = !this.ordenColumnas[property].isDesc;
    this.ordenColumnas[property].isDesc = isDesc;
    this.orderFilter = `${property} ${isDesc ? 'desc': 'asc'}`;
    const direction = isDesc ? -1 : 1;
    this.isDesc = isDesc;
    let sortedDates: any[] = [];
    if (this.ordenColumnas[property].type == "number"){
      sortedDates = this.oficios.sort((p1: {[key:string]:any}, p2?: {[key:string]:any}) =>
      (p1[property] > p2[property]) ? direction : (p1 && p1[property] < p2[property]) ? -1 * direction : 0);
    } else{
      sortedDates = this.oficios.sort((a: {[key:string]:any}, b:{[key:string]:any}) =>
      direction < 0 ? (a[property].localeCompare(b[property])) : (b[property].localeCompare(a[property])));
    }
    this.oficios = sortedDates;
  };

  getFiltrosFormulario(): void {
    this.OficiosService.filtrosFormulario().subscribe(
      { next: (response: any) => {
        if(response?.estados) {
          this.estados = response.estados;
        }
        this.isLoading = false;
      },
      error: (error: any) => {
        this.errorMensaje.show(error, "obtener los estados de los oficios");
        this.isLoading = false;
      }}
    );
  }

  descargarExcel(){
    const url = `${Constants.BASE_OFICIOS}/descarga`;
    const filename = "listaOficiosDocentemas.csv"
    this.estaDescargandoExcel = true;

    this.OficiosService.descargarOficios(url,
      this.filtros.rutDocente,
      this.filtros.rutEncargado,
      this.filtros.rutSostenedor,
      this.filtros.estadoRegistro
    ).subscribe(
      {
        next: (response: any) => {
          Toast.fire(
            "Descarga de docentes",
            "Descarga finalizada",
            "success"
          );
          this.OficiosService.downloadBlob(filename, response);
          this.estaDescargandoExcel = false;
        }, error: (error: any) => {
          this.errorMensaje.show(error, "descargar los docentes");
          this.estaDescargandoExcel = false;
        }
      });
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.getOficios();
  }

  public filtrar(): void {
    this.options.page = 1;
    this.options.size = 10;
    this.getOficios ();
  }

  public limpiar(): void {
    this.filtros = new OficiosFilter();
    this.filtrar();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  obtenerOficio(element: Oficio) {
    this.estadoOficio = element.idEstado;
    this.observacionOficio = element.observacion ? element.observacion : "";
    this.oficioSeleccionadoModal = element;
  }

  rowsTextArea(text: string): number {
    let rows=3;
    if (!text || text=="") return 1;
    if (text.length>170) rows = 5;
    if (text.length<100) rows = 2
    if (text.length<65) rows = 1;
    return rows;
  }

  idTextArea(id: string): string {
    return this.oficioSeleccionadoModal.rutDocente + id;
  }

  modalTexts(text: string): string {
    return text?.trim().length>0 ? text.trim() : " ";
  }

  actualizaLimiteObservacion(event: any) {
    if (this.observacionOficio.length <255) return;
    this.observacionOficio = this.observacionOficio.substring(0,255);
  }


  cambiarEstadoAutomatico( element: any) {
    element.idEstado = Number(element.idEstado);
    this.oficioSeleccionadoModal = element;
    this.estadoOficio = element.idEstado;
    this.guardarCambios(false);
  }

  esFecha(atributo: string): boolean {
    return atributo.toLowerCase().includes('fecha');
  }

  getAtributo(atributo: string): boolean {
    if (atributo == "observacion" || atributo == "estado"){
      return true;
    }
    else{
      return false;
    }
  }

  hideModal(){
    document.createElement('abrirModalPDF').click();
  }

  loadedImage: boolean;

  setUrl(rut:number, archivo: string) {
    this.isLoading = true;
    this.loadedImage = false;
    this.OficiosService.obtenerArchivo(archivo).subscribe({
      next: (response: any) => {
        this.abrirModalPDF.nativeElement.click();
        this.isLoading = false;
        const url: string = response.archivo;
        this.docOficio = url;
        this.imagenOficio = null;
        this.zoomLevel = 1; // Nivel de zoom inicial
        this.rotation = 0;
        if (this.isImage(url)) {
          this.imagenOficio = url;
          this.pdfOficio = null;
          this.pdfUrl = null;
        }
        if(this.isPdf(url)) {
          this.pdfOficio = url;
          this.setContentPdf();
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.mensajeError = true;
        this.hideModal();
        this.errorMensaje.show(error, "obtener el documento para el usuario " + rut);

      }
    });
  }

  onImageLoad() {
    this.isLoading = false;
    this.loadedImage = true;
  }
  
  docOficio: string;
  pdfOficio: string;
  imagenOficio: string;
  zoomLevel: number = 1; // Nivel de zoom inicial
  rotation: number = 0; // Rotación inicial (en grados)

  isImage(archivo:string): boolean {
    return archivo ? /\.(jpg|jpeg|png|gif|bmp|webp)/i.test(archivo) : false;
  }

  isPdf(archivo:string): boolean {
    return archivo ? /\.pdf/i.test(archivo) : false;
  }

  @ViewChild('iframeContainer', { static: false }) iframeContainer!: ElementRef<HTMLDivElement>;

  setContentPdf() {
    this.pdfUrl = `${this.pdfOficio}#zoom=${this.zoomLevel * 100}&rotation=${this.rotation}&toolbar=1`;
    this.reloadIframe(this.pdfUrl);
  }

  onImageError() {
    this.isLoading = false; 
    this.loadedImage = true;
  }
  
  reloadIframe(url: string) {
    const iframeElement = this.iframe.nativeElement;
    this.iframeContainer.nativeElement.removeChild(iframeElement);

    const newIframe = document.createElement('iframe');
    newIframe.src = url;
    newIframe.style.width = '100%';
    newIframe.style.height = '80vh';
    this.renderer.setStyle(newIframe, 'border', 'none');
    this.renderer.setStyle(newIframe, 'opacity', '0'); 
    this.renderer.setStyle(newIframe, 'transition', 'opacity 1.8s ease'); 
    // Reinsertar el iframe en el contenedor
    this.iframeContainer.nativeElement.appendChild(newIframe);

    // Actualizar la referencia al iframe en Angular
    this.iframe = new ElementRef(newIframe);

    this.applyRotation();
    this.addFadeInEffect(newIframe);
  }

  applyRotation() {
    // Aplicar la transformación CSS para rotar el iframe
    const iframeElement = this.iframe.nativeElement;
    const rotationStyle = `rotate(${this.rotation}deg)`;
    this.renderer.setStyle(iframeElement, 'transform', rotationStyle);
    this.renderer.setStyle(iframeElement, 'transform-origin', 'center center');
  }

  addFadeInEffect(iframe: HTMLIFrameElement) {
    setTimeout(() => {
      this.renderer.setStyle(iframe, 'opacity', '1');
    }, 50); 
  }

  lastMouseX: number = 0;
  lastMouseY: number = 0;
  @ViewChild('scrollableContainer') scrollableContainer!: ElementRef;

  startDragging(event: MouseEvent | TouchEvent): void {
    this.isDragging = true;

    const cursor = this.dragCursor.nativeElement;
    cursor.classList.remove('hidden'); // Muestra el círculo

    const position = this.getEventPosition(event);
    this.lastMouseX = position.x;
    this.lastMouseY = position.y;

    this.updateCursorPosition(position);
  }

  drag(event: MouseEvent | TouchEvent): void {
    if (!this.isDragging) return; // Solo arrastra si está presionado el botón

    event.preventDefault(); // Previene comportamientos no deseados (como selección de texto)

    const position = this.getEventPosition(event);

    // Delta de movimiento basado en las coordenadas actuales y las anteriores
    const deltaX = position.x - this.lastMouseX;
    const deltaY = position.y - this.lastMouseY;

    // Referencia al contenedor desplazable
    const container = this.scrollableContainer.nativeElement;

    // Ajusta el desplazamiento del contenedor
    container.scrollLeft -= deltaX;
    container.scrollTop -= deltaY;

    // Actualiza las coordenadas para el siguiente cálculo
    this.lastMouseX = position.x;
    this.lastMouseY = position.y;

    // Actualiza la posición del círculo flotante
    this.updateCursorPosition(position);
  }

  stopDragging(): void {
    this.isDragging = false;

    const cursor = this.dragCursor.nativeElement;
    cursor.classList.add('hidden'); // Oculta el círculo
  }

  getEventPosition(event: MouseEvent | TouchEvent): { x: number; y: number } {
    if (event instanceof MouseEvent) {
      // Coordenadas del mouse
      return { x: event.clientX, y: event.clientY };
    } else {
      // Coordenadas del primer toque (en pantallas táctiles)
      const touch = event.touches[0];
      return { x: touch.clientX, y: touch.clientY };
    }
  }

  updateCursorPosition(position: { x: number; y: number }): void {
    const cursor = this.dragCursor.nativeElement;
    cursor.style.transform = `translate(${position.x - 125}px, ${position.y - 25}px)`; // Centra el círculo
  }

  @ViewChild('dragCursor') dragCursor!: ElementRef;

  
  pdfUrl: string;

  zoomIn(): void {
    this.zoomLevel += 0.12; // Incrementa el zoom
    this.zoomLevel = Math.min(2.0, this.zoomLevel);
    this.setContentPdf();
  }

  zoomOut(): void {
    this.zoomLevel = Math.max(0.6, this.zoomLevel - 0.12); // Decrementa el zoom, sin bajar de 0.1
    this.setContentPdf();
  }

  rotate(): void {
    this.rotation = (this.rotation + 90) % 360; // Incrementa la rotación en 90 grados
    this.applyRotation();
  }

  guardarCambios(modificaObservacion: boolean = true) {
    this.oficioSeleccionadoModal.idEstado = this.estadoOficio;
    if (modificaObservacion) this.oficioSeleccionadoModal.observacion = this.observacionOficio;
    this.OficiosService.modificarOficio(this.oficioSeleccionadoModal).subscribe({
      next: (response) => {
        Toast.fire("Operación exitosa", "El oficio fue editado exitosamente", "success");
        this.getOficios();
      },
      error: (error) => {
        this.mensajeError = true;
        this.errorMensaje.show(error, "modificar el oficio");
      }
    });

  }

  startDrag(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.pageX - (event.target as HTMLElement).closest('div')!.scrollLeft;
    this.scrollLeft = (event.target as HTMLElement).closest('div')!.scrollLeft;
    (event.target as HTMLElement).closest('div')!.classList.add('cursor-grabbing');
  }

  stopDrag(event: MouseEvent) {
    this.isDragging = false;
    (event.target as HTMLElement).closest('div')!.classList.remove('cursor-grabbing');
  }

  onDrag(event: MouseEvent) {
    if (!this.isDragging) return;
    event.preventDefault();
    const x = event.pageX - (event.target as HTMLElement).closest('div')!.offsetLeft;
    const scroll = x - this.startX;
    (event.target as HTMLElement).closest('div')!.scrollLeft = this.scrollLeft - scroll;
  }
  onRightDblClick(event: MouseEvent) {
    event.preventDefault();
    if (event.button === 2) { // Asegurarse de que es clic derecho
      const target = (event.target as HTMLElement).closest('div')!;
      target.scrollLeft = target.scrollWidth - target.clientWidth;
    }
  }

  // Nueva función para manejar el doble clic izquierdo
  onLeftDblClick(event: MouseEvent) {
    if (event.button === 0) { // Asegurarse de que es clic izquierdo
      const target = (event.target as HTMLElement).closest('div')!;
      target.scrollLeft = 0;
    }
  }


  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand: any;
  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad: any;

  private downloadFile(url: string): any {
    return this.http.get(url, { responseType: 'blob', 
      withCredentials: true, })
        .pipe(
            map((result: any) => {
                return result;
            })
        );
}

  pdfSrc: any;
  public openPdf(url: string) { 
    this.http.get(url, { responseType: 'blob', withCredentials: true, }).subscribe((response) => {
      this.pdfSrc = URL.createObjectURL(response);
    });
  }

}
