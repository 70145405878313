import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { QaGrabacionesService } from "../../services/qaGrabacionesService";
import { ImageModalComponent } from "../../other-components/image-modal/image-modal.component";
import { formatTimeFromSeconds } from '../../../../../utils/functions/time-functions';
import Swal from 'sweetalert2';
import {AuthService} from "../../../../../base/services/auth.service";
import {environment} from "../../../../../../environments/environment";
import {AlertsService} from "../../../../../base/services/alerts.service";
import {getTipoEventoById} from "../../constants/eventosRevision";
import {MotivoDTO} from "../../dto/motivoDTO";
import {CategoriaMotivoDTO} from "../../dto/categoriaMotivoDTO";
import {CorreccionDTO} from "../../dto/correccionDTO";
import {DetalleRevisionDTO} from "../../dto/detallesRevisionDTO";
import {MOTIVOS, MOTIVOS_CAMBIO_VALOR} from "../../constants/motivos-constants";
import { QaGrabacionesSupervisorService } from '../../services/qaGrabacionesSupervisorService';
import { RubricaSupervisorComponent } from '../rubrica-supervisor/rubrica-supervisor.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProblemaCorreccionDTO } from '../../dto/problemaCorreccionDTO';

class CustomDataUpdate {
  VIDEO: boolean = false;
  SCREENSHOTS: boolean = false;
  CONFLICTS: boolean = false;
  HISTORY: boolean = false;
  DETAILS: boolean = false;
  CORRECTIONS: boolean = false;
}

@Component({
  selector: 'app-detalle-revision-supervisor',
  templateUrl: './detalle-revision-supervisor.component.html',
  styleUrls: ['./detalle-revision-supervisor.component.css']
})

export class DetalleRevisionSupervisorComponent implements OnInit{

    protected readonly formatTimeFromSeconds = formatTimeFromSeconds;
    customDataUpdate: CustomDataUpdate = null;
    isLoading: boolean = true;
    isCustomUpdating: boolean = false
    isSaving: boolean = false;
  
    deletingCorrection: boolean = false;
    revisionId: number;
    detalleRevision: DetalleRevisionDTO;
    protected readonly getTipoEventoById = getTipoEventoById;
    protected readonly MOTIVOS = MOTIVOS;
    protected readonly MOTIVOS_CAMBIO_VALOR = MOTIVOS_CAMBIO_VALOR;
    correccion: CorreccionDTO;
  
    bypassOperatorAssignment: boolean = environment.features.revisionesGrabaciones.bypassOperatorAssignment;
    bypassRevisionConflicts: boolean = environment.features.revisionesGrabaciones.bypassRevisionConfitcs;
    reopenRevisionEnabled: boolean = environment.features.revisionesGrabaciones.reopenRevision;
  
    errorLoading: boolean = false;
  
    readonly: boolean = !this.bypassOperatorAssignment && true;

    readonlyUser: boolean = false;
    selectedImage: any;
    revisores: string[] = [];

    isCreatingNewCorrection = false;
    isEditingCorrection = false;

    categoriasMotivos: any[] = []; // Lista de categorías y sus motivos obtenidos del servicio
    categoriaSeleccionada: any = null; // Categoría seleccionada para filtrar motivos
    motivosFiltrados: MotivoDTO[] = []; // Motivos filtrados según la categoría seleccionada
    problemas: ProblemaCorreccionDTO[] = [];
    motivos: MotivoDTO[] = [];
    mostrarCamposValor: boolean = false;
    selectedMotivo: any = null;
    correccionForms: FormArray; // FormArray para manejar múltiples formularios dinámicos
  
    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      protected service: QaGrabacionesService,
      protected serviceSupervisor: QaGrabacionesSupervisorService,
      protected authService: AuthService,
      public dialog: MatDialog,
      protected alerts: AlertsService,
      private fb: FormBuilder,
    ) {
      this.revisionId = data.id;
    }
  
    ngOnInit(): void {
      this.readonlyUser = this.authService.getUserGroup().includes("READER_GRABACIONES");
      this.service.obtenerCategoriasYMotivos().subscribe({
        next: (data: CategoriaMotivoDTO[]) => {
          this.categoriasMotivos = data;
          this.motivos = data.flatMap(categoria => categoria.motivos);
          this.filtrarMotivos();
          this.loadRevisionDetails();
        },
        error: (error) => {
          this.alerts.showExceptionAlert(error);
          console.error('Error al cargar motivos y categorías:', error);
          this.isLoading = false;
        }
      });
      if(this.readonlyUser){
        this.readonly = true;
      }
      
    }

    getFormGroupAt(index: number): FormGroup {
      return this.correccionForms.at(index) as FormGroup;
    }
    
    setCategoriaYMotivoPorProblema(problema: any, index: number): void {
      // Busca la categoría correspondiente
      const categoriaSeleccionada = this.categoriasMotivos.find(
        (categoria) => categoria.id === problema.idCategoriaCorregido
      );
    
      if (categoriaSeleccionada) {
        // Filtra los motivos correspondientes a la categoría seleccionada
         // Actualiza los motivos filtrados en el problema
        problema.motivosFiltrados = categoriaSeleccionada.motivos || [];
    
        // Busca el motivo dentro de la categoría seleccionada
        const motivoSeleccionado = categoriaSeleccionada.motivos.find(
          (motivo: MotivoDTO) => motivo.id === problema.idMotivoCorregido
        );
    
        // Verifica si el índice tiene un formulario asociado en el FormArray
        const formGroup = this.correccionForms.at(index) as FormGroup;
        if (formGroup) {
          // Actualiza el formulario con los valores encontrados
          formGroup.patchValue({
            categoria: categoriaSeleccionada.id,
            motivo: motivoSeleccionado?.id || null,
          });
        } else {
          console.warn(`No se encontró un FormGroup para el índice ${index}`);
        }
      } else {
        console.warn(`No se encontró una categoría para idCategoriaCorregido: ${problema.idCategoriaCorregido}`);
      }
    }
    
    
    
    
  
    getMotivoById(id: number): MotivoDTO | null {
      return this.motivos.find(motivo => motivo.id === id) || null;
    }
  
    loadRevisionDetails(options: {customDataUpdate: boolean} | null = null) {
  
      if(options?.customDataUpdate){
        this.isCustomUpdating = true;
      }
  
      this.isLoading = true;
  
      if((this.isCustomUpdating && this.customDataUpdate.CORRECTIONS) || !this.isCustomUpdating) {
        this.correccion = null;
      }
  
      if(!options?.customDataUpdate){
        this.detalleRevision = null;
      }
  
      this.readonly = this.readonlyUser;
  
      this.service.getDetalleRevision(this.revisionId).subscribe({
        next: (detallesRevision) => {
  
          if(this.isCustomUpdating){
            if(this.customDataUpdate.VIDEO){
              this.detalleRevision.videoURL = detallesRevision.videoURL
            }
            if(this.customDataUpdate.DETAILS){
              this.detalleRevision.estadoId = detallesRevision.estadoId
              this.detalleRevision.rutDocente = detallesRevision.rutDocente
              this.detalleRevision.ordenCorrelativo = detallesRevision.ordenCorrelativo
              this.detalleRevision.duracionTotalVideos = detallesRevision.duracionTotalVideos
              this.detalleRevision.archivado = detallesRevision.archivado
              this.detalleRevision.agrupacionAsignatura  = detallesRevision.agrupacionAsignatura
            }
            if(this.customDataUpdate.HISTORY){
              this.detalleRevision.historial = detallesRevision.historial
            }
            if(this.customDataUpdate.SCREENSHOTS){
              this.detalleRevision.screenshots = detallesRevision.screenshots
            }
            if(this.customDataUpdate.CONFLICTS){
              this.detalleRevision.conflictos = detallesRevision.conflictos
            }
          }
          else{
            this.detalleRevision = detallesRevision;
          }
          this.service.obtenerCorreccionesPorRevision(this.revisionId).subscribe({
            next: (correction) => {
              if((this.isCustomUpdating && this.customDataUpdate.CORRECTIONS) || !this.isCustomUpdating) {
                this.correccion = correction;
                if (this.correccion?.problemas?.length) {
                  this.correccionForms = this.fb.array(
                    this.correccion.problemas.map((problema) =>
                      this.fb.group({
                        categoria: [null, Validators.required],
                        motivo: [null, Validators.required],
                      })
                    )
                  );
                  this.correccion.problemas.forEach((problema, index) => {
                    
                    const formGroup = this.correccionForms.at(index) as FormGroup;
                    if (formGroup) {
                      
                      formGroup.patchValue({
                        idCategoriaCorregido: problema.idCategoriaCorregido,
                        idMotivoCorregido: problema.idMotivoCorregido,
                        comentarios: problema.comentarioSupervisor,
                      });
                
                      // Configura la categoría y motivo asociados al problema
                      this.setCategoriaYMotivoPorProblema(problema, index);
                      if (!problema.motivosFiltrados) {
                        problema.motivosFiltrados = [];
                      }
                    } else {
                      console.warn(`No se encontró un FormGroup para el índice ${index}`);
                    }
                  });
                } else {
                  console.warn('No se encontraron problemas para procesar en correccion.');
                }
                
              }
              this.isLoading = false;
              this.isCustomUpdating = false;
            },
            error: (error: Error) => {
              this.errorLoading = true;
              console.error(error);
              this.isLoading = false;
              this.isCustomUpdating = false;
              this.alerts.showExceptionAlert(error)
            }
          });
          this.serviceSupervisor.getRevisoresPorRutDocente(this.detalleRevision.rutDocente)
          .subscribe((data) => {
          this.revisores = data;
          console.log(this.revisores);
          });

        },
        error: (error: Error) => {
          this.errorLoading = true;
          this.isLoading = false;
          this.isCustomUpdating = false;
          this.closeModal();
          this.alerts.showExceptionAlert(error)
        }
      });
    }
  
  
    openModal(screenshot: any): void {
      this.selectedImage = screenshot;
      this.dialog.open(ImageModalComponent, {
        data: {
          image: this.selectedImage.presignedURL,
          frameNumber: this.selectedImage.frameNumber,
          timeSeconds: this.selectedImage.timeSeconds
        },
        width: 'auto',
        height: 'auto',
        maxWidth: 'auto',
        maxHeight: 'auto',
      });
    }
  
    closeModal(): void {
      this.dialog.closeAll();
    }
  
    formatTime(seconds: number): string {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = Math.floor(seconds % 60);
      const milliseconds = Math.floor((seconds % 1) * 100);
  
      const hoursString = hours.toString().padStart(2, '0');
      const minutesString = minutes.toString().padStart(2, '0');
      const secondsString = secs.toString().padStart(2, '0');
      const millisecondsString = milliseconds.toString().padStart(2, '0');
  
      return `${hoursString}:${minutesString}:${secondsString}`;
    }
  
    


    verRubrica(rutDocente: number, revisor: string): void {
      const dialogRef = this.dialog.open(RubricaSupervisorComponent, {
        width: 'auto',
        height: 'auto',
        maxWidth: '900px', 
        data: { rutDocente, revisor }, 
      });
      dialogRef.afterClosed().subscribe((result) => {
      });
    }

    actualizarProblemas() {
      this.correccion.problemas.forEach((problema, index) => {
        if (problema.idEstado) {
          let requestBody = {
            idEstado: problema.idEstado,
            idMotivo: '', 
            comentario: problema.comentarioSupervisor || '',
          };
    
    
          if (problema.idEstado === 3) {
           
            const formGroup = this.correccionForms.at(index) as FormGroup;
    
            
            if (formGroup.invalid) {
              console.warn(`Formulario inválido para el problema ${problema.id}`);
              return; 
            }
    
            const motivo = formGroup.value.motivo;
    
            requestBody = {
              idEstado: problema.idEstado,
              idMotivo: motivo,
              comentario: problema.comentarioSupervisor || '',
            };
          }

          // Retraso para mostrar las alertas secuencialmente
      setTimeout(() => {
        this.serviceSupervisor.actualizarProblemaSupervisor(problema.id, requestBody).subscribe({
          next: () => {
            this.alerts.showSuccessAlert(
              `Problema ${problema.id} actualizado correctamente.`
            );
          },
          error: (error) => {
            console.error(
              `Error al actualizar problema ${problema.id} con estado ${problema.idEstado}:`,
              error
            );
          },
        });
      }, index * 2000); // Incrementa 1 segundo para cada problema según su índice
        } else {
          console.warn(`Problema ${problema.id} no tiene un estado válido para actualizar.`);
        }
      });
    }
    
    
    getMotivosPorCategoria(categoriaId: number | null): MotivoDTO[] {
      if (!categoriaId) {
        return [];
      }
    
      const categoria = this.categoriasMotivos.find(cat => cat.id === categoriaId);
      return categoria ? categoria.motivos : [];
    }
    
    
    onEstadoChange(problema: any) {
      if (problema.idEstado === 3) {
        problema.nuevaCategoriaId = null; // Resetear categoría seleccionada
        problema.nuevoMotivoId = null;    // Resetear motivo seleccionado
      }
    }
    
    getDescripcionMotivo(nuevaCategoriaId: number | null, nuevoMotivoId: number | null): string {
      if (!nuevaCategoriaId || !nuevoMotivoId) {
        return 'Descripción no disponible';
      }
    
      const motivos = this.getMotivosPorCategoria(nuevaCategoriaId);
      const motivo = motivos.find((motivo) => motivo.id === nuevoMotivoId);
      return motivo?.descripcion || 'Descripción no disponible';
    }
    
    onCategoriaChange(categoriaId: number, index: number): void {
      // Busca la categoría seleccionada por su ID
      const categoriaSeleccionada = this.categoriasMotivos.find(
        (categoria) => categoria.id === categoriaId
      );
    
      if (categoriaSeleccionada) {
        // Actualiza la lista de motivos filtrados para el problema específico
        const problema = this.correccion.problemas[index]; // Obtén el problema por índice
        if (problema) {
          problema.motivosFiltrados = categoriaSeleccionada.motivos || [];
        }
    
        // Resetea el valor del motivo en el formulario correspondiente
        const formGroup = this.correccionForms.at(index) as FormGroup;
        if (formGroup) {
          formGroup.patchValue({ motivo: null });
        }
      } else {
        console.warn(`No se encontró una categoría con el ID: ${categoriaId}`);
      }
    }
    
    
    filtrarMotivos(): void {
      if (this.categoriaSeleccionada) {
        const motivosSeleccionados = this.problemas.map(p => p.idMotivo);
        this.motivosFiltrados = this.categoriaSeleccionada.motivos
          .filter((motivo:any) => !motivosSeleccionados.includes(motivo.id));
      } else {
        this.motivosFiltrados = [];
      }
    }

  onMotivoChange(motivoId: number | null): void {

    
    this.selectedMotivo = this.motivosFiltrados.find(m => m.id === motivoId);
    if (!this.selectedMotivo) {
      return;
    }

    this.correccionForms.get('comentarios')?.reset();

    this.mostrarCamposValor = MOTIVOS_CAMBIO_VALOR.includes(this.selectedMotivo.id);

    if (this.mostrarCamposValor) {

      this.correccionForms.get('valorNuevo')?.setValidators(Validators.required);
      this.correccionForms.get('valorNuevo')?.updateValueAndValidity();


      // Establecer `valorAnterior` en base a motivos específicos de RUT o correlativo
      if ([MOTIVOS.RUT_CON_ERROR_EN_DIGITO, MOTIVOS.RUT_NO_CORRESPONDE].includes(this.selectedMotivo.id)) {
        this.correccionForms.get('valorAnterior')?.setValue(this.detalleRevision.rutDocente);
      } else if (this.selectedMotivo.id === MOTIVOS.CORRELATIVO_INCORRECTO) {
        this.correccionForms.get('valorAnterior')?.setValue(this.detalleRevision.ordenCorrelativo);
      } else {
        this.correccionForms.get('valorAnterior')?.reset();
      }
    } else {
      this.correccionForms.get('valorNuevo')?.clearValidators();
      this.correccionForms.get('valorNuevo')?.updateValueAndValidity();
      this.mostrarCamposValor = false;
      this.correccionForms.get('valorAnterior')?.reset();
      this.correccionForms.get('valorNuevo')?.reset();
    }
  }

}
