
import { Overlay, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MAT_DATEPICKER_SCROLL_STRATEGY, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FroalaViewModule } from 'angular-froala-wysiwyg';
import { NgxEditorModule } from 'ngx-editor';
import { NgxPaginationModule } from 'ngx-pagination';
import { BaseModule } from '../base/base.module';
import { CargasMasivasComponent } from './components/carga-masiva/carga-masiva.component';
import { CierreProcesosComponent } from './components/cierre-procesos/cierre-procesos.component';
import { EvaluacionesHistoricasExtendidaComponent } from './components/evaluaciones-historicas-extendida/evaluaciones-historicas-extendida.component';
import { EvaluacionesHistoricasComponent } from './components/evaluaciones-historicas/evaluaciones-historicas.component';
import { EventosComponent } from './components/eventos/eventos.component';
import { FichaDocenteComponent } from './components/ficha-docente/ficha-docente.component';
import { TimelineValidacionComponent } from './components/ficha-docente/timeline-validacion/timeline-validacion.component';
import { AperturaCajaComponent } from './components/grabaciones/apertura-caja/apertura-caja.component';
import { FichaSeguimientoComponent } from './components/grabaciones/ficha-seguimiento/ficha-seguimiento.component';
import { RecepcionBultoComponent } from './components/grabaciones/recepcion-bulto/recepcion-bulto.component';
import { HistorialLlamadasComponent } from './components/historial-llamadas/historial-llamadas.component';
import { MantenedorFeedMainComponent } from './components/mantenedor-feed/mantendor-feed-main/mantenedor-feed-main.component';
import { MantenedorFeedModalComponent } from './components/mantenedor-feed/mantenedor-feed-modal/mantenedor-feed-modal/mantenedor-feed-modal.component';
import { MantenedorUsuariosListComponent } from './components/mantenedor-usuarios/mantenedor-usuarios-list/mantenedor-usuarios-list.component';
import { OficiosComponent } from './components/oficios/oficios.component';
import { PortafolioAvanceDocentemasComponent } from './components/portafolio-avance/portafolio-avance.component';
import { PortafolioDocentemasComponent } from './components/portafolio-docentemas/portafolio-docentemas.component';
import { PortafolioComponent } from './components/portafolio-historico/portafolio.component';
import { RegistroDocenteListComponent } from './components/registro-docentes/registro-docente-list/registro-docente-list.component';
import { RegistroDocenteFormComponent } from './components/registro-encargado-evaluacion/registro-encargado-evaluacion-form/registro-encargado-evaluacion-form.component';
import { RegistroEncargadoEvaluacionListComponent } from './components/registro-encargado-evaluacion/registro-encargado-evaluacion-list/registro-encargado-evaluacion-list.component';
import { RepresentanteLegalFormComponent } from './components/representantes-legales/representante-legal-form/representante-legal-form.component';
import { RepresentantesLegalesComponent } from './components/representantes-legales/representante-legal-list/representantes-legales.component';
import { TicketsMdaComponent } from './components/tickets-mda/tickets-mda.component';
import { UsuariosOperacionesMdaComponent } from './components/usuarios-operaciones-mda/usuarios-operaciones-mda.component';
import { QaGrabacionesComponent } from './components/grabaciones/qa-grabaciones/qa-grabaciones.component';
import { DescargaInformesComponent } from './components/descarga-informes/descarga-informes.component';
import { ReportesComponent } from './components/reportes/reportes.component';
import { DirectoresListComponent } from './components/directores/directores.component';
import {
  DetalleRevisionComponent
} from "./components/grabaciones/qa-grabaciones/detalle-revision/detalle-revision.component";
import {MatDialogModule} from "@angular/material/dialog";
import {
  NuevaCorreccionComponent
} from "./components/grabaciones/qa-grabaciones/forms/nueva-correccion/nueva-correccion.component";
import {ImageModalComponent} from "./components/grabaciones/other-components/image-modal/image-modal.component";
import { NominasModalComponent } from './components/grabaciones/templates/nominas-modal/nominas-modal.component';
import { LootieAnimationsComponent } from "./components/grabaciones/templates/lootie-animations/lootie-animations.component";
import { InsertarSdComponent } from './components/grabaciones/apertura-caja/insertar-sd/insertar-sd.component';
import {UtilsModule} from "../utils/utils-module";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatTabsModule} from "@angular/material/tabs";
import {
  RubricaRevisionComponent
} from "./components/grabaciones/qa-grabaciones/forms/rubrica-revision/rubrica-revision.component";
import {MatRadioModule} from "@angular/material/radio";
import {FormatTimePipe} from "./components/grabaciones/pipes/format-time-pipe";
import { DescargaInformesRbdSostenedorComponent } from './components/descarga-informes-rbd-sostenedor/descarga-informes-rbd-sostenedor.component';
import {
  QaGrabacionesSupervisorComponent
} from "./components/grabaciones/qa-grabaciones-supervisor/qa-grabaciones-supervisor.component";
import {
  SupervisorTableComponent
} from "./components/grabaciones/qa-grabaciones-supervisor/tables/supervisor-table.component";
import {
  GroupedRevisionsTableComponent
} from "./components/grabaciones/qa-grabaciones-supervisor/tables/grouped-revisions-table/grouped-revisions-table.component";
import {
  IndividualRevisionsTableComponent
} from "./components/grabaciones/qa-grabaciones-supervisor/tables/individual-revisions-table/individual-revisions-table.component";
import {
  LoadingSpinnerComponent
} from "./components/grabaciones/other-components/loading-spinner/loading-spinner.component";
import { DetalleRevisionSupervisorComponent } from './components/grabaciones/qa-grabaciones-supervisor/detalle-revision-supervisor/detalle-revision-supervisor.component';
import { RubricaSupervisorComponent } from './components/grabaciones/qa-grabaciones-supervisor/rubrica-supervisor/rubrica-supervisor.component';


const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD HH:mm:ss Z',
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm:ss Z', // Formato que se mostrará en el input
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD HH:mm:ss Z',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    SupervisorTableComponent,
    GroupedRevisionsTableComponent,
    IndividualRevisionsTableComponent,
    LoadingSpinnerComponent,
    FormatTimePipe,
    RubricaRevisionComponent,
    RegistroEncargadoEvaluacionListComponent,
    RegistroDocenteFormComponent,
    RegistroDocenteListComponent,
    EvaluacionesHistoricasComponent,
    RepresentantesLegalesComponent,
    RepresentanteLegalFormComponent,
    MantenedorUsuariosListComponent,
    FichaDocenteComponent,
    EvaluacionesHistoricasExtendidaComponent,
    OficiosComponent,
    CargasMasivasComponent,
    UsuariosOperacionesMdaComponent,
    MantenedorFeedMainComponent,
    MantenedorFeedModalComponent,
    TicketsMdaComponent,
    EventosComponent,
    CierreProcesosComponent,
    PortafolioComponent,
    TimelineValidacionComponent,
    HistorialLlamadasComponent,
    PortafolioAvanceDocentemasComponent,
    PortafolioDocentemasComponent,
    DescargaInformesComponent,
    FichaSeguimientoComponent,
    RecepcionBultoComponent,
    AperturaCajaComponent,
    QaGrabacionesComponent,
    QaGrabacionesSupervisorComponent,
    DetalleRevisionComponent,
    ReportesComponent,
    NuevaCorreccionComponent,
    ImageModalComponent,
    NominasModalComponent,
    InsertarSdComponent,
    DetalleRevisionSupervisorComponent,
    RubricaSupervisorComponent,
    DirectoresListComponent,
    DescargaInformesRbdSostenedorComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    NgxPaginationModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatCardModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressSpinnerModule,
    FontAwesomeModule,
    MatChipsModule,
    TextFieldModule,
    BaseModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxEditorModule,
    MatMomentDateModule,
    FroalaViewModule.forRoot(),
    MatDialogModule,
    LootieAnimationsComponent,
    UtilsModule,
    MatGridListModule,
    MatTabsModule,
    MatRadioModule
  ],
  exports: [
    MatChipsModule,
  ],
  providers: [
    MatDatepickerModule,
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    { provide: MAT_DATEPICKER_SCROLL_STRATEGY,
      deps: [Overlay, ScrollStrategyOptions],
      useFactory: (overlay: Overlay, scrollStrategyOptions: ScrollStrategyOptions) => {
        return () => overlay.scrollStrategies.block();
      },},
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RegistrosModule { }
