
interface Menus {
  routerlink: string,
  label: string,
  icon: string,
  color: string,
  authorizationRoles: AuthorizationRoles
}

interface AuthorizationRoles {
  [key: string]: string[];
}

export class ModulosAutorizacion {

  modulo2024: Menus[] = [
    {
      "routerlink": "ficha-docente",
      "label": "Estado Docente",
      "icon": "ED",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", "ADM_MDA", "OP_MDA", "ADM_CORRECCIONES",
        "ADM_INSTRUMENTOS", "EJECUTIVO_GRABACIONES", "ADM_GRABACIONES", "OPERADOR_GRABACIONES"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", "ADM_CORRECCIONES", "ADM_GRABACIONES"],
      }
    },
    {
      "routerlink": "registro-encargados-evaluacion-list",
      "label": "Encargados Evaluación",
      "icon": "EE",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", "ADM_MDA", "OP_MDA", "ADM_CORRECCIONES", 
        "ADM_INSTRUMENTOS", "EJECUTIVO_GRABACIONES", "ADM_GRABACIONES", "OPERADOR_GRABACIONES"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", "ADM_CORRECCIONES"],
      }
    },
    {
      "routerlink": "representantes-legales",
      "label": "Representantes Legales",
      "icon": "RL",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", "ADM_MDA", "OP_MDA", "ADM_CORRECCIONES", 
        "ADM_INSTRUMENTOS", "EJECUTIVO_GRABACIONES", "ADM_GRABACIONES", "OPERADOR_GRABACIONES"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", "ADM_CORRECCIONES"],
      }
    },
    {
      "routerlink": "oficios",
      "label": "Oficios",
      "icon": "OF",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", 
        "ADM_INSTRUMENTOS"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP"],
      }
    },
    {
      "routerlink": "directores",
      "label": "Directores",
      "icon": "DR",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_MDA", "OP_MDA"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_MDA", "OP_MDA"],
      }
    },

    {
      "routerlink": "eventos",
      "label": "Eventos Docentemás",
      "icon": "EV",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_CORRECCIONES", 
        "ADM_INSTRUMENTOS"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO"],
      }
    },
    {
      "routerlink": "cierre-procesos-docentemas",
      "label": "Cierre Procesos",
      "icon": "PR",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_INSTRUMENTOS"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO"],
      }
    },
    {
      "routerlink": "mantenedor-usuarios-list",
      "label": "Usuarios DocenteMás",
      "icon": "UD",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO"],
      }
    },
    {
      "routerlink": "portafolio-actual",
      "label": "Avance Portafolio",
      "icon": "AP",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_INSTRUMENTOS", "ADM_CORRECCIONES"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO","ADM_CORRECCIONES"],
      }
    },
    {
      "routerlink": "portafolio-docentemas",
      "label": "Portafolio Docentemás",
      "icon": "PF",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_INSTRUMENTOS","ADM_CORRECCIONES"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_CORRECCIONES"],
      }
    },
    {
      "routerlink": "noticias", //solo en dev y local
      "label": "Noticias",
      "icon": "NT",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO"],
      }
    },
    {
      "routerlink": "cargas-masivas", //solo en dev y local
      "label": "Cargas Masivas",
      "icon": "CM",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO"],
      }
    },
    {
      "routerlink": "descarga-informes",
      "label": "Descarga Informes",
      "icon": "DI",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", "ADM_INSTRUMENTOS"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP"],
      }
    },
    {
      "routerlink": "informes-rbd-sostenedor",
      "label": "Informes RBD y Sost.",
      "icon": "RS",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", "ADM_INSTRUMENTOS"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP"],
      }
    },
  ];

  moduloRegistroHistorico: Menus[] = [
    {
      "routerlink": "evaluaciones-historicas",
      "label": "Evaluaciones",
      "icon": "EH",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", "ADM_MDA", "OP_MDA","ADM_INSTRUMENTOS"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP"],
      }
    },
    {
      "routerlink": "evaluaciones-historicas-extendida",
      "label": "Evaluaciones Extendidas",
      "icon": "EX",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", 
        "ADM_INSTRUMENTOS"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP"],
      }
    },
    {
      "routerlink": "registro-docente-list",
      "label": "Estado Docente",
      "icon": "DH",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", "ADM_MDA", "OP_MDA", 
        "ADM_INSTRUMENTOS", "EJECUTIVO_GRABACIONES", "ADM_GRABACIONES", "OPERADOR_GRABACIONES"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "OP_CPEIP", "ADM_MDA", "OP_MDA", 
        "ADM_INSTRUMENTOS", "EJECUTIVO_GRABACIONES", "ADM_GRABACIONES", "OPERADOR_GRABACIONES"],
      }
    },
    {
      "routerlink": "tickets-mda",
      "label": "Tickets MDA",
      "icon": "TK",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_MDA", "OP_MDA", "ADM_INSTRUMENTOS"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO"],
      }
    },
    {
      "routerlink": "portafolio2023",
      "label": "Portafolio 2023",
      "icon": "P2",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_MDA","ADM_CORRECCIONES",  "ADM_INSTRUMENTOS"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO"],
      }
    },
    {
      "routerlink": "historial-llamadas",
      "label": "Historial de Llamadas",
      "icon": "HL",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_MDA", "OP_MDA"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO"],
      }
    },
    {
      "routerlink": "reportes",
      "label": "Reportes",
      "icon": "RE",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_MDA", "ADM_CORRECCIONES", 
        "ADM_INSTRUMENTOS", "READER_GRABACIONES", "ADM_GRABACIONES"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_CORRECCIONES", "ADM_REGISTRO", "ADM_GRABACIONES"],
      }
    },
  ];

  moduloTracklog: Menus[] = [
    {
      "routerlink": "tracklog/ficha-seguimiento",
      "label": "Ficha Seguimiento",
      "icon": "FS",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES", "EJECUTIVO_GRABACIONES", "OPERADOR_GRABACIONES"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_MDA", 
         "EJECUTIVO_GRABACIONES", "READER_GRABACIONES", "ADM_GRABACIONES", "OPERADOR_GRABACIONES"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES", "EJECUTIVO_GRABACIONES", "OPERADOR_GRABACIONES"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES", "EJECUTIVO_GRABACIONES", "OPERADOR_GRABACIONES"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES", "READER_GRABACIONES", "EJECUTIVO_GRABACIONES", "OPERADOR_GRABACIONES"],
      }
    },
    {
      "routerlink": "tracklog/qa-grabaciones",
      "label": "Revisiones Grabaciones",
      "icon": "RG",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO", "REVISOR_GRABACIONES", "ADM_GRABACIONES"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "REVISOR_GRABACIONES",
        "ADM_GRABACIONES", "READER_GRABACIONES", "OPERADOR_GRABACIONES"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO", "REVISOR_GRABACIONES", "ADM_GRABACIONES"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO", "REVISOR_GRABACIONES", "ADM_GRABACIONES"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES"],
      }
    },
    {
      "routerlink": "tracklog/recepcion-bulto-grabaciones",
      "label": "Recepción de Bultos",
      "icon": "RB",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", 
         "ADM_GRABACIONES", "READER_GRABACIONES", "OPERADOR_GRABACIONES"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES"],
      }
    },
    {
      "routerlink": "tracklog/apertura-caja-grabaciones",
      "label": "Apertura de Cajas y SD",
      "icon": "SD",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO",
         "ADM_GRABACIONES", "READER_GRABACIONES", "OPERADOR_GRABACIONES"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO","READER_GRABACIONES", "ADM_GRABACIONES"],
        "SELLAR": ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES"],
        "REABRIR": ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES"],
      }
    },
    {
      "routerlink": "tracklog/qa-grabaciones-supervisor",
      "label": "Supervisar revisiones Grabaciones",
      "icon": "SR",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES", "SUPERVISOR_GRABACIONES"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES", "SUPERVISOR_GRABACIONES"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES", "SUPERVISOR_GRABACIONES"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES", "SUPERVISOR_GRABACIONES"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_GRABACIONES", "SUPERVISOR_GRABACIONES"],
      }
    },
  ];

  moduloAdministracion: Menus[] = [
    {
      "routerlink": "mantenedor-usuarios-cognito",
      "label": "Usuarios Operaciones",
      "icon": "OP",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO"],
      }
    },
    {
      "routerlink": "gestor-usuarios-mda",
      "label": "Usuarios MDA",
      "icon": "UM",
      "color" : "rgba(15,105,180,0.33)",
      "authorizationRoles": {
        "CREATE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_MDA"],
        "READ" :   ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_MDA"],
        "UPDATE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_MDA"],
        "DELETE" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_MDA"],
        "DOWNLOAD" : ["ROLE_ADMIN", "ADM_REGISTRO", "ADM_MDA"],
      }
    },
  ];
  
}
  