import { FormControl, Validators } from "@angular/forms";

const nameTableColumns: string[] = [
    "Rut Representante",
    "Empresa Sostenedora",
    "Establecimiento Asociado",
    "Tipo",
    "Nombre Completo",
    "Primer Apellido",
    "Segundo Apellido",
    "Correo",
    "Teléfono",
    "Acciones"
]
const fieldForm: string[] = [
    "Rut Representante",
    "Rut Empresa",
    "Nombre Empresa",
    "Tipo",
    "Nombres",
    "Primer Apellido",
    "Segundo Apellido",
    "Correo",
    "Teléfono"
]

const fieldControl: FormControl[] = [
    new FormControl('', [Validators.required, Validators.maxLength(8)]),
    new FormControl('', [Validators.required, Validators.maxLength(9)]),
    new FormControl('', [Validators.required, Validators.maxLength(100)]),
    new FormControl('', Validators.required),
    new FormControl('', [Validators.required, Validators.maxLength(100)]),
    new FormControl('', [Validators.required, Validators.maxLength(100)]),
    new FormControl('', [Validators.required, Validators.maxLength(100)]),
    new FormControl('', [Validators.required, Validators.maxLength(100), Validators.email]),
    new FormControl('', [Validators.required, Validators.maxLength(10)]),
];

export class RepresentanteLegal {
    [index: string] :any;
    rut: number;
    rutEmpresa: number;
    nombreEmpresa: string;
    tipo: string;
    nombres:string;
    primerApellido: string;
    segundoApellido: string;
    correo: string;
    telefono: number;
    rbd: number;

    constructor(){
        this.rut = null;
        this.rutEmpresa= null;
        this.nombreEmpresa= null;
        this.tipo= null;
        this.nombres = null;
        this.primerApellido= null;
        this.segundoApellido= null;
        this.correo= null;
        this.telefono= null;
        this.rbd=null;
    }

    getControl(index: number): FormControl {
        fieldControl[index].setValue('', {emitEvent: false});
        return fieldControl[index];
    }

    getNameColumns(index: number): string{
        return nameTableColumns[index];
    }

    getFieldForm(index: number): string {
        return fieldForm[index];
    }
}
