<mat-card  class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
    <div class="panel-body m-2 p-2 flex flex-col gap-4"  #FILTRO >
        <div class="flex flex-col gap-4 px-2">
          <p class="text-blue-700 text-2xl font-bold border-4 w-fit p-2 border-b-red-600 border-r-red-600 border-t-blue-600 border-l-blue-600 rounded-tl-2xl rounded-br-2xl">Grabaciones 2024</p>
          <p class="text-blue-950 text-2xl font-bold">Recepción Bulto</p>
          <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
            <div class="flex flex-row gap-1 py-2">
              <mat-icon>filter_list</mat-icon>
              <p class="text-lg font-bold">Filtros</p>
            </div>
                <div class="row" aria-label="sección de inputs">
                  <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
                    <mat-form-field class="w-full">
                      <mat-label>Folio Bulto</mat-label>
                      <input type="string" matInput [(ngModel)]="filtros.folio"
                        placeholder="Formato Ej: ZZ00001" name="Folio Bulto" />
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
                    <mat-form-field class="w-full">
                      <mat-label>N° Seguimiento</mat-label>
                      <input type="text" matInput [(ngModel)]="filtros.seguimiento"
                        placeholder="	1111-11.111.111" name="Nº Seguimiento" />
                    </mat-form-field>
                  </div>

                  <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
                    <mat-form-field class="w-full">
                      <mat-label>Rut Sostenedor</mat-label>
                      <input type="number" matInput [(ngModel)]="filtros.rutSostenedor"
                        placeholder="12345678" name="RutSostenedor" />
                    </mat-form-field>
                  </div>

                  <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
                    <mat-form-field appearance="outline" class="w-full">
                      <mat-label>Estado Bulto</mat-label>
                      <mat-select name="estado bulto" [(ngModel)]="filtros.estado">
                        <mat-option [value]="'1'">Buen Estado</mat-option>
                        <mat-option [value]="'2'">Mal Estado</mat-option>

                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                    <mat-card-actions class="d-flex row">
                      <button
                        type="button"
                        data-te-ripple-init
                        (click)="filtrar()"
                        data-te-ripple-color="light"
                        class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                        <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                        <p class="pr-1"></p> Filtrar
                      </button>
                      <button
                        type="button"
                        data-te-ripple-init
                        (click)="limpiar()"
                        data-te-ripple-color="light"
                        class="d-flex w-28  mx-2 justify-items-center justify-center text-center ajustar items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                        <p class="pr-1"></p> Limpiar
                      </button>
                      <div class="float-right col d-flex justify-content-start" *ngIf="permisosDescarga">
                        <button
                            *ngIf="length>0"
                            type="button"
                            data-te-ripple-init
                            (click)="descargarBultos()"
                            data-te-ripple-color="light"
                            class="d-flex w-fit px-2 mx-2 justify-items-center justify-center text-center ajustar items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                            <fa-icon *ngIf="estaDescargandoExcel" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
                            <svg *ngIf="!estaDescargandoExcel" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pr-2" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
                            <div *ngIf="!estaDescargandoExcel" class="text-[14px]">Descargar</div>
                            <div *ngIf="estaDescargandoExcel" class="text-[14px]">Descargando</div>
                        </button>
                      </div>
                      <div class="float-right col d-flex justify-content-end" *ngIf="permisosCRUD">
                        <button data-bs-toggle="modal" data-bs-target="#agregarBultoModal"
                        class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                          Recepcionar Nuevo Bulto
                        </button>
                      </div>
                    </mat-card-actions>
          </div>
        </div>
      </div>



      <div class="overflow-x-scroll rounded-2xl border border-gray-200 m-2 p-2" aria-label="sección tabla" >
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading" />
        <table class="w-full table-auto table-hover rounded-2xl table bg-gray-50" role="table">
          <thead class="bg-blue-700 ">
            <tr>
              <th scope="col" class="rounded-tl-2xl pl-2 pr-4 py-3 text-left text-blue-100 text-sm font-black w-14 items-baseline" ></th>
              <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >Bulto</th>
              <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >Nº Seguimiento</th>
              <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >Rut Sostenedor</th>
              <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >Estado Bulto</th>
              <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >Observación Estado Bulto</th>
              <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >Fecha Recepción</th>
              <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >Operador</th>
              <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " *ngIf="permisosCRUD" >Acción</th>
              <th scope="col" class="rounded-tr-2xl pl-2 pr-4 py-3 text-right text-blue-100 w-14" ></th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr *ngFor="let element of response">
              <td class="negrita-tabla">
              </td>
              <td class="negrita-tabla">
                <p class="font-bold text-grey-900">
                  {{element.folio}}
                </p>
              </td>
              <td class="negrita-tabla">
                {{element.seguimiento}}
              </td>
              <td class="negrita-tabla">
                {{element.rutSostenedor}}
              </td>
              <td class="negrita-tabla">
                <div class="inline-flex gap-1 justify-items-center">
                  <svg *ngIf="element.estado == 1" class="w-6 h-6 fill-green-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                  <svg *ngIf="element.estado == 2" class="w-6 h-6" style="color: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>ionicons-v5-r</title><path d="M479,447.77,268.43,56.64a8,8,0,0,0-14.09,0L43.73,447.77a8,8,0,0,0,7.05,11.79H472A8,8,0,0,0,479,447.77ZM281.38,411.48h-40v-40h40Zm-4-63.92h-32l-6-160h44Z" fill="red"></path></svg>
                  {{element.estado == 1 ? 'Buen Estado': element.estado == 2 ? 'Mal Estado' : ''}}
                </div>
              </td>
              <td class="negrita-tabla text-gray-500">
                {{element.observaciones}}
              </td>
              <td class="negrita-tabla text-gray-500">
                {{element.fecha | date:'d/M/yy'}}
              </td>
              <td class="negrita-tabla text-gray-500">
                {{element.operador}}
              </td>
              <td class=" overflow-hidden shadow-4" *ngIf="permisosCRUD">
                <button data-bs-toggle="modal" data-bs-target="#editarBultoModal" hidden #abremodal></button>
                <button class=" text-gray-700 px-4 py-2 text-sm font-medium hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                (click)="variablesEditar(element.id, element.folio, element.seguimiento, element.fecha, element.estado, element.observaciones)">
                  <i class="fas fa-edit mr-2"></i>
                  editar
                </button>
                <div *ngIf="containRol()">
                  <button
                    (click) = "ventanaEliminar(element.seguimiento, element.folio)"
                    class="text-gray-700 px-4 py-2 text-sm font-medium hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                    <i class="fas fa-trash-alt"></i>
                    eliminar
                  </button>
                </div>
              </td>
              <td class="negrita-tabla">
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
        [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="paginador de fichas">
      </mat-paginator>
    </mat-card>

    <div class="modal fade" id="agregarBultoModal" tabindex="-1" aria-labelledby="agregarBultoLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content rounded-lg shadow-lg">
          <div class="modal-header flex justify-between items-center">
            <h5 class="modal-title font-medium" id="agregarBultoLabel">Agregar Bulto</h5>
            <div>
              <button #cerrarFormModal type="button" class="border hover:border-black py-1 px-2 rounded-xl"
              data-bs-dismiss="modal" aria-label="Close">&times;</button>
            </div>
          </div>
          <div class="modal-body p-6">
            <form>
              <!-- Botones -->
              <div class="mb-4">
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>  Folio Bulto</mat-label>
                  <input matInput  [(ngModel)]="folio"  name="folio"
                    placeholder="Ingrese folio del bulto" type="string">
                    <mat-hint align="end" class="text-red-700" *ngIf="!folio">
                      Este campo es requerido
                    </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>  N° Seguimiento</mat-label>
                  <input matInput  [(ngModel)]="seguimiento" name="seguimiento"
                    placeholder="Ingrese número de seguimiento" type="string">
                    <mat-hint align="end" class="text-red-700" *ngIf="!seguimiento">
                      Este campo es requerido
                    </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>Fecha de Recepción DD/MM/AAAA</mat-label>
                  <input matInput [matDatepicker]="picker" [max]="today" [min]="minDate" (dateChange)="onDateChange($event)"
                    placeholder="Fecha" [(ngModel)]="fechaRecepcion" name="fechaRecepcion">
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-hint align="end" class="text-red-700" *ngIf="!fechaRecepcion">
                    Este campo es requerido
                  </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>Estado</mat-label>
                  <mat-select name="estado bulto" [(ngModel)]="estado" >
                    <mat-option [value]="'1'">Buen Estado</mat-option>
                    <mat-option [value]="'2'">Mal Estado</mat-option>

                  </mat-select>
                  <mat-hint align="end" class="text-red-700" *ngIf="estado == 0 || !estado">
                    Este campo es requerido
                  </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>  Observación</mat-label>
                  <input matInput  [(ngModel)]="observacion" name="observacion"
                    placeholder="Ingrese observaciones del bulto" type="string">
                </mat-form-field>
              </div>
              <div class="flex justify-end gap-2">

                <button mat-button type="button" class="text-gray-500" data-bs-dismiss="modal"
                (click)="resetearVariables()">Cancelar</button>
                <button mat-raised-button color="primary" type="submit"  type="submit"
                (click)="ventanaAgregar()"
                [disabled]="!folio || !seguimiento || estado == 0 || !fechaRecepcion || !estado "
                data-bs-dismiss="modal">
                  Agregar
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="editarBultoModal" tabindex="-1" aria-labelledby="editarBultoLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content rounded-lg shadow-lg">
          <div class="modal-header flex justify-between items-center">
            <h5 class="modal-title font-medium" id="editarBultoLabel">Edición Bulto</h5>
            <div>
              <button #cerrarFormModal type="button" class="border hover:border-black py-1 px-2 rounded-xl"
              data-bs-dismiss="modal" aria-label="Close">&times;</button>
            </div>
          </div>
          <div class="modal-body p-6">
            <form>
              <!-- Botones -->
              <div class="mb-4">
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>  Folio Bulto</mat-label>
                  <input matInput  [(ngModel)]="folioEdicion"  name="folio"
                    placeholder="Ingrese folio del bulto" type="string"
                    [disabled]="this.tieneCajaAsociada">
                    <mat-hint align="end" class="text-red-700" *ngIf="!folioEdicion">
                      Este campo es requerido
                    </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>  N° Seguimiento</mat-label>
                  <input matInput  [(ngModel)]="seguimientoEdicion" name="seguimientoEdicion"
                    placeholder="Ingrese número de seguimiento" type="text"
                    [disabled]="this.tieneCajaAsociada">
                    <mat-hint align="end" class="text-red-700" *ngIf="!seguimientoEdicion">
                      Este campo es requerido
                    </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>Fecha de Envío DD/MM/AAAA</mat-label>
                  <input matInput [matDatepicker]="pickerEdicion" [max]="today" [min]="minDate" (dateChange)="onDateChange($event)"
                    placeholder="Fecha" [(ngModel)]="fechaRecepcionEdicion" name="fechaRecepcionEdicion">
                  <mat-datepicker-toggle matIconSuffix [for]="pickerEdicion"></mat-datepicker-toggle>
                  <mat-datepicker #pickerEdicion></mat-datepicker>
                  <mat-hint align="end" class="text-red-700" *ngIf="!fechaRecepcionEdicion">
                    Este campo es requerido
                  </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>Estado</mat-label>
                  <mat-select name="estadoEdicion"  [(value)]="estadoEdicion" >
                    <mat-option [value]=1>Buen Estado</mat-option>
                    <mat-option [value]=2>Mal Estado</mat-option>
                    <mat-hint align="end" class="text-red-700" *ngIf="estado == 0">
                      Este campo es requerido
                    </mat-hint>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>  Observación</mat-label>
                  <input matInput  [(ngModel)]="observacionEdicion" name="observacionEdicion"
                    placeholder="Ingrese observaciones del bulto" type="text">
                </mat-form-field>
              </div>
              <div class="flex justify-end gap-2">

                <button mat-button type="button" class="text-gray-500" data-bs-dismiss="modal"
                (click)="resetearVariablesEdicion()">Cancelar</button>
                <button mat-raised-button color="primary" type="submit"  type="submit"
                (click)="ventanaEditar()"
                [disabled]="!folioEdicion || !seguimientoEdicion || estadoEdicion == 0 || !fechaRecepcionEdicion "
                data-bs-dismiss="modal">
                  Editar
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
