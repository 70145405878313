import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { QaGrabacionesSupervisorService } from '../../../services/qaGrabacionesSupervisorService';
import { MatDialog } from '@angular/material/dialog';
import { DetalleRevisionSupervisorComponent } from '../../detalle-revision-supervisor/detalle-revision-supervisor.component';

@Component({
  selector: 'app-grouped-revisions-table',
  templateUrl: './grouped-revisions-table.component.html',
  styleUrls: ['./grouped-revisions-table.component.scss'],
})
export class GroupedRevisionsTableComponent implements OnChanges, OnInit {
  @Input() groupedRevisionsResponse!: any;
  @Output() pageChange = new EventEmitter<any>(); // Emisor de evento

  revisions: any[] = [];
  total: number = 0;
  currentPage: number = 1;
  pageSize: number = 10;
  pageSizeOptions: number[] = [5, 10, 30, 50];
  reloadDataAfterRevisionDetailsClosed: boolean = true;

  constructor(
    protected service: QaGrabacionesSupervisorService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    if (this.groupedRevisionsResponse) {
      this.processResponse();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['groupedRevisionsResponse'] && this.groupedRevisionsResponse) {
      this.processResponse();
    }
  }

  private processResponse(): void {
    this.revisions = this.groupedRevisionsResponse.list || [];
    this.total = this.groupedRevisionsResponse.total || 0;
    this.currentPage = this.groupedRevisionsResponse.pageNum || 1;
    this.pageSize = this.groupedRevisionsResponse.pageSize || 10;
  }

  onPageChange(event: any): void {
    this.pageChange.emit({
      page: event.pageIndex + 1, 
      size: event.pageSize, 
      pageSizeOptions: this.pageSizeOptions
    });
  }

  verDetalles(revisionId: number) {
      const dialogRef = this.dialog.open(DetalleRevisionSupervisorComponent, {
        width: 'auto',
        height: 'auto',
        maxWidth: '900px',
        data: { id: revisionId },
        disableClose: true
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(this.reloadDataAfterRevisionDetailsClosed){
          this.processResponse();
        }
      });
  }
  
}