<mat-card class="p-4 flex flex-col min-h-screen overflow-hidden" style="box-shadow: none !important;">
  <div class="panel-body m-2 p-2 flex flex-col gap-4 flex-grow overflow-hidden">
    <div class="flex flex-col gap-4 px-2">
      <p class="text-blue-700 text-2xl font-bold border-4 w-fit p-2 border-b-red-600 border-r-red-600 border-t-blue-600 border-l-blue-600 rounded-tl-2xl rounded-br-2xl">Grabaciones 2024</p>
      <div class="inline-flex gap-2">
        <p class="text-blue-950 text-2xl font-bold">Revisiones grabaciones</p>
        <p matTooltip="Prepárese el café mientras preparamos los datos." *ngIf="isLoading" class="rounded-2xl cursor-wait bg-blue-700 pt-1 px-2 font-bold text-sm text-white animate-pulse">CARGANDO</p>
        <p matTooltip="Servicio funcionando sin problemas." *ngIf="!isLoading" class="rounded-2xl bg-green-500 pt-1 px-2 font-bold text-sm text-white">ONLINE</p>
      </div>

      <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2 border-l-blue-500 shadow-4">
        <div class="flex flex-row gap-1 py-2">
          <mat-icon>filter_list</mat-icon>
          <p class="text-lg font-bold">Filtros</p>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <mat-form-field class="mat-mdc-form-field w-full">
              <mat-label>Rut Docente</mat-label>
              <input type="text" matInput placeholder="Ingrese Rut del Docente" [(ngModel)]="filtros.rutDocente">
            </mat-form-field>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <mat-form-field class="mat-mdc-form-field w-full">
              <mat-label>Estado Revisiones</mat-label>
              <mat-select placeholder="Seleccione un Estado" [(ngModel)]="filtros.estadoRevision">
                <mat-option value="">Todos</mat-option>
                <mat-option *ngFor="let estado of estadosFiltroFiltrados" [value]="estado.id">
                  {{estado.display_text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <mat-checkbox class="mt-4" [(ngModel)]="filtros.mostrarArchivados">Incluír revisiones archivadas</mat-checkbox>
          </div>
        </div>

        <div class="d-flex">
          <div class="float-left d-flex align-self-start">
            <mat-card-actions class="d-flex row">
              <button type="button"
                      class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                      (click)="filtrarDatos(true)">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>

                <p>Filtrar</p>
              </button>
              <button type="button"
                      class="d-flex w-28  mx-2 justify-items-center justify-center text-center ajustar items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"

              (click)="limpiarFiltro(true)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                <p>Limpiar</p>
              </button>
            </mat-card-actions>
          </div>
        </div>
      </div>

    </div>

    <div class="flex-grow overflow-hidden mt-4">
      <!-- Spinner de carga -->
      <div *ngIf="isLoading" class="flex flex-col items-center justify-center bg-white w-full h-full overflow-y-hidden"
           style="min-height: 10vh; height: 80vh; position: relative;">
        <app-loading-spinner [text]="'Obteniendo datos de revisiones, por favor espere...'"></app-loading-spinner>
      </div>

      <div *ngIf="!isLoading && errorLoading" class="flex flex-col items-center justify-center bg-white p-6 w-full max-w-lg mx-auto text-center">
        <p class="text-2xl font-semibold text-red-600 mb-4">
          Tuvimos un problema al cargar la informaciónc
        </p>

        <ng-container *ngIf="error; else genericError">
          <p class="text-lg font-bold text-red-700">¡Ups! Algo salió mal:</p>
          <p class="mt-3 text-red-600"><em>"{{ error.message }}"</em></p>
          <p class="mt-2 text-sm text-gray-500">(Código del error: {{ error.code }})</p>
        </ng-container>

        <ng-template #genericError>
          <p class="text-lg font-bold text-red-700">¡Ups! Algo salió mal al intentar cargar los datos.</p>
          <p class="mt-2 text-red-600">Por favor, intenta nuevamente más tarde.</p>
        </ng-template>

        <button (click)="filtrarDatos()" class="mt-6 bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition-all duration-300 ease-in-out">
          Intentar nuevamente
        </button>
      </div>

      <!-- Contenido de la tabla y paginadores -->
      <div *ngIf="!isLoading && !errorLoading">
        <!-- Primer paginador -->
        <mat-paginator #paginator1 [length]="length" [pageSize]="paginationOptions.size" [pageIndex]="paginationOptions.page - 1"
                       [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)"
                       [showFirstLastButtons]="true">
        </mat-paginator>

        <!-- Tabla de datos -->
        <div class="overflow-y-hidden overflow-x-scroll rounded-2xl border border-gray-200 m-2 p-2">
          <table class="w-full table-auto table-hover rounded-2xl bg-gray-50" role="table">
            <thead class="bg-blue-700 sticky top-0 z-10">
            <tr>
              <th class="rounded-tl-2xl pl-2 pr-4 py-3 text-left text-blue-100 text-sm font-black w-1/6 md:w-1/6">Datos Generales</th>
              <th class="rounded-tr-2xl pl-2 pr-4 py-3 text-left text-blue-100 text-sm font-black w-5/6 md:w-5/6">Revisiones Videos</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let rutItem of listaRuts; let i = index" [ngClass]="i % 2 === 0 ? 'bg-slate-100' : 'bg-white'">
              <!-- Datos Generales -->
              <td class="align-top">
                <div class="p-2 rounded-md bg-transparent">
                  <div class="flex flex-col gap-1">
                    <div class="flex items-center gap-1">
                      <mat-icon fontIcon="person" class="text-blue-600"></mat-icon>
                      <p class="text-gray-800 font-medium text-sm">RUT docente: <span class="font-bold">{{ rutItem.rutDocente }}</span></p>
                    </div>

                    <div class="flex items-center gap-1">
                      <mat-icon fontIcon="video_library" class="text-blue-600"></mat-icon>
                      <p class="text-gray-800 font-medium text-sm">Archivos de Video: <span class="font-bold">{{ rutItem.revisiones.length }}</span></p>
                    </div>
                    <div *ngIf="rutItem.conflictos && rutItem.conflictos.length > 0" class="mt-2">

                      <p *ngIf="!bypassRevisionConflicts" class="text-red-600 font-semibold text-sm">Se han detectado conflictos.<br/> No se podrá finalizar revisiones para este docente hasta que sean resueltos.</p>
                      <p *ngIf="bypassRevisionConflicts" class="text-orange-600 font-semibold text-sm">Se han detectado conflictos.</p>

                      <button type="button"
                              class="w-full text-white py-1 px-3 border rounded-md text-xs flex items-center justify-center mt-1"
                              [ngClass]="{
                                'bg-red-500 hover:bg-red-600 border-red-600': !bypassRevisionConflicts,
                                'bg-orange-500 hover:bg-orange-600 border-orange-600': bypassRevisionConflicts
                              }"
                              (click)="verConflictos(rutItem.conflictos)">
                        <mat-icon fontIcon="report_problem" class="mr-1 text-white"></mat-icon>
                        Ver conflictos
                      </button>

                    </div>
                  </div>
                </div>
              </td>

              <!-- Columna Revisiones Videos -->
              <td class="align-top">
                <div class="flex flex-wrap gap-4">

                  <div class="revision-item w-full md:w-[100%] md:max-w-[450px] bg-white border rounded-lg p-4 mb-4 relative" *ngFor="let revisionItem of rutItem.revisiones">
                    <!-- Sección de previsualización del video -->
                    <div class="flex flex-col md:flex-row items-start gap-4">
                      <div class="relative w-full md:w-1/2 h-32 bg-gray-200 rounded-lg overflow-hidden">
                        <img *ngIf="revisionItem.screenshotURLS && revisionItem.screenshotURLS.length > 0" [src]="revisionItem.screenshotURLS[0].presignedURL" alt="Screenshot" class="w-full h-full object-cover">
                        <div *ngIf="revisionItem.revision?.duracion && revisionItem.screenshotURLS && revisionItem.screenshotURLS.length > 0"
                             class="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white text-[9px] p-0.5 flex items-center justify-center space-x-1">
                          <span>{{ revisionItem.revision.duracion | formatTime }}</span>
                        </div>

                        <div *ngIf="!revisionItem.screenshotURLS || revisionItem.screenshotURLS.length === 0" class="flex items-center justify-center w-full h-full bg-gray-100">
                          <mat-icon fontIcon="movie" class="text-gray-500 text-4xl"></mat-icon>
                        </div>
                      </div>


                      <div class="flex flex-col justify-between w-full md:w-1/2">
                        <div class="flex items-start md:items-center justify-between">
                          <div class="flex flex-col">
                            <p class="text-lg font-bold flex items-center">Revisión #{{ revisionItem.revision.id }}</p>
                            <span *ngIf="revisionItem.revision.archivado" class="mt-1 flex items-center text-xs font-bold text-red-600"><mat-icon fontIcon="archive" class="mr-1"></mat-icon>Archivado</span>
                          </div>
                          <div class="flex items-center gap-1 mt-2 md:mt-0">
                            <mat-icon [fontIcon]="service.getEstadoById(revisionItem.revision.estadoId).icon" [ngStyle]="{'color': service.getEstadoById(revisionItem.revision.estadoId).display_color}"></mat-icon>
                            <p [ngStyle]="{'color': service.getEstadoById(revisionItem.revision.estadoId).display_color}" class="font-medium text-sm">
                              {{ service.getEstadoById(revisionItem.revision.estadoId).display_text }}
                            </p>
                          </div>
                        </div>
                        <p class="text-sm text-gray-600 mt-1">Archivo: {{ revisionItem.revision.archivoVideo }}</p>
                        <p class="text-xs text-gray-500">Fecha: {{ revisionItem.revision.fechaRevision | date:'dd-MM-yyyy HH:mm:ss' }}</p>
                      </div>
                    </div>

                    <!-- Botón de Ver detalles -->
                    <div class="mt-4">
                      <button *ngIf="revisionItem.revision.estado !== 0" type="button" class="w-full bg-primary hover:bg-blue-700 text-white py-2 px-4 border border-blue-700 rounded text-sm flex items-center justify-center" (click)="verDetalles(revisionItem.revision.id)">
                        <mat-icon fontIcon="visibility" class="mr-1 text-white"></mat-icon>
                        Ver detalles
                      </button>
                    </div>
                  </div>

                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- Segundo paginador -->
        <mat-paginator #paginator2 [length]="length" [pageSize]="paginationOptions.size" [pageIndex]="paginationOptions.page - 1"
                       [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)"
                       [showFirstLastButtons]="true">
        </mat-paginator>
      </div>
    </div>
  </div>
</mat-card>
