import { Component, ElementRef, ViewChild } from '@angular/core';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { DescargaInformesService } from '../../services/descarga-informes.service';

const menus: {key: number, value: string}[] = [
  {key: 1, value: "Informe Establecimiento"},
  {key: 2, value: "Informe Sostenedor"}
]

@Component({
  selector: 'app-descarga-informes-rbd-sostenedor',
  templateUrl: './descarga-informes-rbd-sostenedor.component.html',
  styleUrls: ['./descarga-informes-rbd-sostenedor.component.css']
})
export class DescargaInformesRbdSostenedorComponent {
  menus = menus;
  mostrarCambioDeInforme!: boolean;
  payload: any;
  searching!: boolean;
  rutDocente!: number;
  informe!: number;
  informeNoEncontradoBD: boolean = false;
  informeNoEncontradoBucket: boolean = false;
  isLoading = false;
  mensajeError: boolean = false;
  @ViewChild('iframe') iframe!: ElementRef;

  constructor(
    private descargaInformesService: DescargaInformesService,
    private errorMensaje: ErrorMensajeService,
  ) { }

  ngOnInit(): void {
    
  }

  buscar(rut:number,informe:number){
    this.informeNoEncontradoBD = false;
    this.informeNoEncontradoBucket = false;
    this.isLoading = true;
    this.iframe.nativeElement.contentWindow.location.replace("about:blank");
    this.descargaInformesService.obtenerInformeRBDSostenedor(rut, informe).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        this.iframe.nativeElement.contentWindow.location.replace(response.body.data);
      },
      error: (error: any) => {
        this.isLoading = false;
        if (error.status === 400) {
          this.informeNoEncontradoBD = true;
          return;
        }
        if (error.status === 404) {
          this.informeNoEncontradoBucket = true;
          return;
        }
        this.mensajeError = true;
        this.errorMensaje.show(error, "obtener el documento para el usuario " + rut);
      },

    });
    }

  onInputChange(value: string){
    if ( [7,8].includes(value?.length) ) {
      this.informe = 2;
      this.mostrarCambioDeInforme = true;

      setTimeout(() => {
        this.mostrarCambioDeInforme = false;
      }, 6000);
    }
  }
}
