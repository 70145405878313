<mat-tab-group>
  <!-- Tab for Individual Revisions -->
  <mat-tab label="Detallada">
    <ng-template mat-tab-label>
      <mat-icon class="mr-2">table_rows</mat-icon>
      Vista Detallada
    </ng-template>
    <ng-container *ngIf="isLoadingIndividual; else individualRevisionsContent">
      <app-loading-spinner [text]="'Consultando datos, por favor espere...'"></app-loading-spinner>
    </ng-container>
    <ng-template #individualRevisionsContent>
      <app-individual-revisions-table [individualRevisionsResponse]="individualRevisions"
      (pageChange)="handlePageChange('individualRevisions', $event)">
    </app-individual-revisions-table>
    </ng-template>
  </mat-tab>

  <!-- Tab for Grouped Revisions -->
  <mat-tab label="Agrupada">
    <ng-template mat-tab-label>
      <mat-icon class="mr-2">folder_shared</mat-icon>
      Vista Agrupada
    </ng-template>
    <ng-container *ngIf="isLoadingGrouped; else groupedRevisionsContent">
      <app-loading-spinner [text]="'Consultando datos, por favor espere...'"></app-loading-spinner>
    </ng-container>
    <ng-template #groupedRevisionsContent>
      <app-grouped-revisions-table 
      [groupedRevisionsResponse]="groupedRevisions" 
        (pageChange)="handlePageChange('groupedRevisions', $event)"></app-grouped-revisions-table>
    </ng-template>
  </mat-tab>
</mat-tab-group>
