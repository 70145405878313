<mat-card  class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
    <div class="panel-body m-2 p-2 flex flex-col gap-4"  #FILTRO >
        <div class="flex flex-col gap-4 px-2">
          <p class="text-blue-950 text-2xl font-bold">Descarga de Informes RBD y Sostenedor</p>
          <div class="filtros2 form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"> </mat-progress-bar>
            <div class="flex flex-row gap-1 pt-2 pb-4">
              <mat-icon>filter_list</mat-icon>
              <p class="text-lg font-bold">Filtros</p>
            </div>
  
            <div class="w-full flex items-center" aria-label="sección de inputs">
              <div class="w-3/5 px-2 inline-flex gap-2">
                <mat-form-field class="w-2/4" [hidden]="!informe">
                  <mat-label class="inline-flex gap-1 text-sm">
                    <p *ngIf="informe==1" class="font-bold">RBD</p>
                    <p *ngIf="informe==2" class="font-bold">RUT</p>
                    a consultar
                  </mat-label>
                  <input
                    #inputSearch
                    type="text"
                    matInput
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    placeholder="Digite el valor"
                    [(ngModel)]="rutDocente"
                    (input)="onInputChange(inputSearch.value)"
                    name="rutDocente"
                  />
                  <mat-hint class="text-xs text-slate-500" *ngIf="informe ==2 && ![7,8].includes(rutDocente?.toString().length)">
                    Digite un rut sin guión con 7 u 8 dígitos
                  </mat-hint>
                  <mat-hint 
                    class="text-xs text-slate-500"
                    *ngIf="informe ==1 && rutDocente?.toString().length < 7">
                    Digite un RBD 
                  </mat-hint>
                </mat-form-field>
            
                <mat-form-field appearance="outline" class="w-2/4" [ngClass]="{'animate-bounce': mostrarCambioDeInforme}">
                  <mat-label>Tipo de Informe</mat-label>
                  <mat-select [(ngModel)]="informe" name="estadoAcceso">
                    <mat-option *ngFor="let item of menus" [value]="item.key">
                      {{ item.value }}
                    </mat-option>
                  </mat-select>
                  <mat-hint class="text-sm text-red-800" *ngIf="!informe">
                    Seleccione informe
                  </mat-hint>
                  <mat-hint class="text-xs text-blue-800" *ngIf="mostrarCambioDeInforme">
                    Cambié el tipo de informe porque lo ingresado parece ser sostenedor.
                  </mat-hint>
                </mat-form-field>
              </div>
            
              <div class="ml-auto">
                <button
                  *ngIf="informe && rutDocente && 
                        (informe==1 && rutDocente?.toString().length <7 ||
                         informe == 2 && [7,8].includes(rutDocente?.toString().length)
                  )"
                  type="button"
                  data-te-ripple-init
                  (click)="buscar(rutDocente, informe)"
                  data-te-ripple-color="light"
                  class="w-28 flex mx-2 justify-center items-center my-2 rounded-xl bg-primary pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  <svg
                    *ngIf="searching"
                    class="animate-spin"  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 4335 4335" width="20"  height="20"
                  >
                    <path fill="#ffffff" d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1120 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1120 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"/>
                  </svg>
                  <svg
                    *ngIf="!searching"
                    width="24" height="24"  stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                  >
                    <path  d="M21 12V10C21 7.23858 18.7614 5 20 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round"  stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C20.3431 14 15 15.3431 15 17C15 18.6569 20.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p class="pr-1"></p> Buscar
                </button>
              </div>
            </div>
            
        </div>
      </div>
    </div>
    <div *ngIf="informeNoEncontradoBD" class="py-2 bg-transparent">
      <div class="col h-100 px-8">
        <div class="alert alert-dismissible alert-info-historial text-center items-center" id="alertTop" role="alert">
          <b class="text-center items-center">El Docente no tiene informe asociado al proceso anterior</b>
        </div>
      </div>
    </div>
    <div *ngIf="informeNoEncontradoBucket" class="py-2 bg-transparent">
      <div class="col h-100 px-8">
        <div class="alert alert-dismissible alert-info-historial text-center items-center" id="alertTop" role="alert">
          <b class="text-center items-center">Problema al descargar el informe</b>
        </div>
      </div>
    </div>
    <iframe title="pdf" #iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" height="800"
            toolbar="0" width="100%"></iframe>
  </mat-card>
  