import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PaginationOptions } from "../models/paginationOptions";
import { catchError, map, Observable, throwError } from "rxjs";
import { Constants } from "src/app/base/constants/constants";
import { Directores } from "../models/director";


@Injectable({
  providedIn: 'root'
})
export class DirectorService{
  constructor(
    private http: HttpClient
  ) { }

  directoresList(options: PaginationOptions,
    rut: number = null,
    rbd: number = null,
    dependencia:number,
    region: number,
    rutSostenedor:number,
    rutEncargado:number): Observable<any> {
    let params = this.obtenerParamsDirector(rut, rbd, dependencia, region, rutSostenedor, rutEncargado);
    const url = `${Constants.URL_SERVICE.BASE_DIRECTOR + Constants.URL_SERVICE.DIRECTOR.LISTAR}?page=${options.page}&size=${options.size}`;
    return this.http.get(url, { params: params }).pipe(map(response => response));
  }

  obtenerParamsDirector(rut:any,rbd:any, dependencia: any, region:any, rutSostenedor:any,rutEncargado:any,): HttpParams {
    let params = new HttpParams();
      if (rut && rut > 0) {
        params = params.set('rut',rut);
      }
      if (rbd && rbd > 0) {
        params = params.set('rbd',rbd);
      }
      if (dependencia && dependencia > 0) {
        params = params.set('dependencia',dependencia);
      }
      if (region && region > 0) {
        params = params.set('region',region);
      }
      if (rutSostenedor && rutSostenedor > 0) {
        params = params.set('rutSostenedor',rutSostenedor);
      }
      if (rutEncargado && rutEncargado > 0) {
        params = params.set('rutEncargado',rutEncargado);
      }
      return params;
  }

  getFile(url:string, rut:number, rbd:number, dependencia:number, region: number, rutSostenedor:number, rutEncargado:number): Observable<any>{
    let params = this.obtenerParamsDirector(rut, rbd, dependencia, region, rutSostenedor, rutEncargado);
    return this.http.get(url, { responseType: 'blob', params:params }).pipe(
      catchError(this.handleError)
    );
  }

  downloadBlob(filename:string, blob:any) {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
        dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);

  }
  handleError(error:any) {
    let errorMessage = '';
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
    return throwError(errorMessage);
  }

  agregarDirector(director: Directores):  Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_DIRECTOR + Constants.URL_SERVICE.DIRECTOR.AGREGAR}`;
    return this.http.post(url, director);

  }

  editarDirector(director: Directores):  Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_DIRECTOR + Constants.URL_SERVICE.DIRECTOR.EDITAR}`;
    return this.http.put(url, director);

  }

  eliminarDirector (rut: number, rbd:number): Observable<any> {
      const url = `${Constants.URL_SERVICE.BASE_DIRECTOR + Constants.URL_SERVICE.DIRECTOR.ELIMINAR}`;
      return this.http.delete(url,{
        params:{
          rut: rut,
          rbd: rbd}
        });
    }

  getNombreEstablecimiento (rbd:string): Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_DIRECTOR + Constants.URL_SERVICE.DIRECTOR.NOMBRE_ESTABLECIMIENTO}?rbd=${rbd}`;
    return this.http.get(url,{responseType: 'text'});

  }

  getDirectorEstablecimiento (rbd:string): Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_DIRECTOR + Constants.URL_SERVICE.DIRECTOR.DIRECTOR_ESTABLECIMIENTO}?rbd=${rbd}`;
    return this.http.get(url,{responseType: 'text'});

  }

  datosEncargados(rutDirector:number):Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_DIRECTOR + Constants.URL_SERVICE.DIRECTOR.GET_ENCARGADOS}`;
    return this.http.get(url, {params:{rutDirector: rutDirector}});
  }


}
