<div class="flex flex-col justify-center items-center py-10 space-y-6">
  <!-- Contenedor del spinner -->
  <div class="relative h-28 w-28">
    <!-- Spinner animado -->
    <div class="absolute inset-0 animate-spin-slow rounded-full bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 blur-md"></div>
    <!-- Contenedor del ícono central -->
    <div class="absolute inset-0 flex items-center justify-center">
      <mat-icon
        style="font-size: 36px; height: 36px; width: 36px; filter: blur(0.5px);"
        class="text-white animate-pulse"
      >
        cloud_download
      </mat-icon>
    </div>
  </div>

  <!-- Texto de carga -->
  <div class="text-center space-y-2">
    <p class="text-blue-600 text-lg font-semibold flex items-center gap-2 animate-bounce">
      <mat-icon
        style="font-size: 24px; animation: spin 2s linear infinite;"
        class="text-blue-500"
      >
        hourglass_empty
      </mat-icon>
      {{ text }}
    </p>
    <p class="text-gray-500 text-sm animate-pulse">{{ subtitle }}</p>
  </div>
</div>
