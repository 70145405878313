import { MotivoDTO } from "./motivoDTO";

export class ProblemaCorreccionDTO {
  id: number;
  idMotivo: number;
  codigoMotivo: string |null;
  codigoCategoria: string |null;
  motivoNombre: string;
  solucionable: boolean;
  valorNuevo: string | null = null;
  valorAnterior: string | null = null;
  comentario: string | null = null;
  permiteComentarios: boolean = false;
  idEstado: number;
  nuevaCategoriaId: any;
  nuevoMotivoId: any;
  comentarioSupervisor: string;
  idMotivoCorregido: number;
  idCategoriaCorregido: number;
  motivosFiltrados?: MotivoDTO[]; // Opcional, ya que no estará siempre presente
}
