import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Animate,
  Ripple,
  Sidenav,
  Tooltip,
  initTE
} from "tw-elements";
import { AuthService } from '../../services/auth.service';
import { AutorizacionesService } from '../../services/autorizaciones.service';
import { fader } from '../../services/route-animations';
import { ModulosAutorizacion } from '../../models/ModulosAutorizacion';

interface Menus {
  routerlink: string,
  label: string,
  icon: string,
  color: string,
  authorizationRoles: AuthorizationRoles
}

interface AuthorizationRoles {
  [key: string]: string[];
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [
    fader
  ]
})
export class HomeComponent implements OnInit {
  email: string = "usuario@iie.cl"
  grupos!: string[];
  sidebarCollapsed: boolean = false;
  instance!: any;
  @ViewChild('colapsable') colapsable: ElementRef;
  menuActive: string = "inicio";
  firstTime: boolean = true;
  indiceCargasMasivas: number;
  inicio: {
    "routerlink": "inicio",
    "label": "Inicio",
    "icon": "../../../../assets/images/sidebar/house.svg"
  };
  private routerSubscription: Subscription;
  private modulos: ModulosAutorizacion = new ModulosAutorizacion();

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }


  modulo2024: Menus[];

  moduloTracklog: Menus[];

  moduloAdministracion: Menus[];

  moduloRegistroHistorico: Menus[];

  allModules: Menus[];

  env: string = 'dev';

  stopBouncingToggleMenu() {
    this.firstTime = false;
  }

  constructor(
    @Inject(Router) private router: Router,
    private authService: AuthService,
    private permisoPara: AutorizacionesService
  ) { }

  showMenu(menu: string) {
    this.menuActive = menu;
  }

  slimClick() {
    let instance = Sidenav.getInstance(
      document.getElementById("sidenav-4")
    );
    if (!instance) window.location.reload();
    instance.toggleSlim();
    this.sidebarCollapsed = instance._slimCollapsed;
  }


  seleccionarMenu(id: string) {
    const menu: HTMLElement = document.getElementById(id);
    menu.classList.toggle('active');
  }

  nameEnv () {
    const host = environment.host;
    return host.includes('dev') ? 'dev' : host.includes('cert') ? 'cert' : 'prod';
  }

  concatNonNullArrays(...arrays: any) {
    // Filtra los arrays que no son nulos ni indefinidos
    return arrays
        .filter((array:any) => array != null) 
        .reduce((acc:any, array:any) => acc.concat(array), []); 
  }

  ngOnInit(): void {
    this.env = this.nameEnv();
    initTE({ Sidenav, Ripple, Tooltip, Animate }, { allowReinits: true });
    const sidenav = document.getElementById("sidenav-4");
    this.instance = Sidenav.getInstance(sidenav);
    this.instance.show();
    this.slimClick();
    this.modulo2024 = this.construirModuloMenus(this.modulos.modulo2024);
    this.moduloRegistroHistorico = this.construirModuloMenus(this.modulos.moduloRegistroHistorico)
    this.moduloTracklog = this.construirModuloMenus(this.modulos.moduloTracklog);
    this.moduloAdministracion = this.construirModuloMenus(this.modulos.moduloAdministracion);
    this.allModules = this.concatNonNullArrays(this.modulo2024, this.moduloRegistroHistorico, this.moduloTracklog, this.moduloAdministracion);

    const currentPath = this.router.url;
    this.seleccionarMenuActual(currentPath);
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const path = event.url;
        this.seleccionarMenuActual(path);
      }
    });
  }

  construirModuloMenus(modulo: Menus[]): any
    {
      let tempMod = [];
      for (let menu of modulo) {
        let rolesUsuario = [];
        for (const key in menu.authorizationRoles) {
          const roles = menu.authorizationRoles[key];
          if (this.permisoPara.containRol(roles)) {
            rolesUsuario.push(menu.authorizationRoles)
          }
        }
        
        if (rolesUsuario.length > 0) { tempMod.push(menu) }
      }
      return tempMod.length > 0 ? tempMod : undefined;
    }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  seleccionarMenuActual(path: string) {
    path = path.replace("/private/","");
    const findInModulo2024 = this.allModules.filter(item => item.routerlink === path)
    if (findInModulo2024) {
      this.menuActive = findInModulo2024[0]?.icon;
      return;
    }
    this.menuActive = null;
  }

  asignaModulos(
    moduloMenu: string[],
    menu: Menus[]):
    Menus[]
  {
      return menu.filter((item, index) => moduloMenu.indexOf(item.icon) > -1 );
  }

  cerrarSesion(): void {
    this.authService.logout();
  }
}
