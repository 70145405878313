export class RevisionFilterSupervisor {
  rutDocente?: number;
  estadoRevision?: number;
  mostrarArchivados?: boolean;
  mostrarAsignadas?: boolean;
  idRevisor: string;
  cantidadVideos?: number;
  simboloCantidad?: string;

  constructor(
    rutDocente?: number,
    estadoRevision?: number,
    idRevisor?: string,
    cantidadVideos?: number,
    simboloCantidad?: string
  ) {
    this.rutDocente = rutDocente;
    this.estadoRevision = estadoRevision;
    this.mostrarArchivados = false;
    this.idRevisor = idRevisor;
    this.cantidadVideos = cantidadVideos;
    this.simboloCantidad = simboloCantidad;
  }
}
